import React from 'react';
import gettext from 'airborne/gettext';
import PropTypes from 'prop-types';
import Input from 'midoffice/newforms/widgets/Input';
import Button from 'midoffice/components/Button';
import {injectFormContext} from 'midoffice/newforms/decorators';
import schema from './LoginSchema';
import {noErrors} from 'midoffice/newforms/helpers';
import {connect} from 'react-redux';
import {sendLogin, changeLoginValue, showForgotPassword} from 'airborne/store/modules/login/actions/login';
import Col from 'react-bootstrap/Col';
import {
    getUserSettingsErrors,
    getUserSettingsLoading,
    getUserSettingsValue
} from 'airborne/store/modules/user_settings/selectors/userSettings';
import {Link} from 'react-router-dom';

@injectFormContext
class Editor extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        showForgotPassword: PropTypes.func.isRequired,
        errors: PropTypes.object,
        value: PropTypes.object,
        source: PropTypes.string,
        timeout: PropTypes.string,
        minutes: PropTypes.string,
        loading: PropTypes.bool,
    };
    handleClick = () => {
        this.props.showForgotPassword();
        this.props.onChange({value: this.props.value, errors: 'reset'});
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.onSubmit();
    }
    renderErrors = () => {
        const {source, timeout} = this.props;
        const normalizedTimeout = Number(timeout);
        const errors = {
            'admin-reset': gettext('You were signed out because admin forced password reset.'),
            'password-change': gettext('You were signed out because you successfully changed your password.'),
            'expired': gettext('loginExpiredMessage', {count: normalizedTimeout}),
            'other-device': gettext('You were signed out because your account is being used somewhere else.'),
        };

        return (
            errors[source] && <section className="floated-form__body__message">
                <strong> {errors[source]}</strong>
            </section>
        );
    }
    renderBottomErrors = () => {
        const {source, minutes} = this.props;
        const errors = {
            'bad-sso-login': gettext('We are unable to verify your login credentials - please try again or contact your agency\'s administrator for assistance.'),
            'bad-sso-response': gettext('Error on receiving response from your agency\'s single sign-on (SSO) server. Please inform your agency\'s IT department. If you know your username and password, you can log in directly above.'),
            'bad-sso-saml': gettext('Error while processing SAML Response. Please inform your agency\'s IT department. If you know your user name and password, you can log in directly above.'),
            'account-suspended': gettext('Your account is suspended. Please contact your administrator.'),
            'account-locked': gettext('For your security, this account is temporarily locked. Please try again in {minutes} minute, reset password or contact customer support.', {minutes}),
        };

        return (
            errors[source] && <section className="floated-form__body__message">
                <strong> {errors[source]}</strong>
            </section>
        );
    }
    render() {
        const {errors, loading} = this.props;
        const extra = {
            labelCol: 2,
            inputCol: 12,
        };
        const errorMessages = errors && errors.errors;
        return (<form className="form-horizontal" onSubmit={this.handleSubmit}>
            <h3 className="floated-form__body__header">{gettext('Sign in')}</h3>
            {this.renderErrors()}
            <Input.Field
                placeholder={gettext('Email')}
                name="email"
                autoFocus
                {...extra} />
            <Input.Field
                placeholder={gettext('Password')}
                type={'password'}
                name="password"
                {...extra} />
            <div className="form-group">
                <Col xs={12}>
                    <Link className="btn-link--narrow pull-right" to="/ui/forgot-password/">{gettext('Reset your password')}</Link>
                </Col>
            </div>
            {this.renderBottomErrors()}
            {errorMessages && errorMessages.map((error, idx) => (
                <section key={idx} className="floated-form__error">
                    <strong>{error.message}</strong>
                </section>
            ))}
            <div className="floated-form__control">
                <Button bsStyle="info"
                    bsSize="lg"
                    disabled={loading}
                    type={'submit'}
                    className="btn-block">{gettext('Sign in')}</Button>
            </div>
        </form>);
    }
}

@connect((state) => {
    const query = new URLSearchParams(location.search);
    const source = query.get('source');
    const timeout = query.get('timeout');
    return {value: getUserSettingsValue(state, 'login'),
        loading: getUserSettingsLoading(state, 'login'),
        errors: getUserSettingsErrors(state, 'login'),
        source: source,
        timeout: timeout,
    };
}, {onSave: sendLogin, onChange: changeLoginValue, showForgotPassword})
class Login extends React.Component {
    static propTypes = {
        value: PropTypes.shape({
            'email': PropTypes.string,
            'password': PropTypes.string,
        }),
        errors: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        showForgotPassword: PropTypes.func.isRequired,
        source: PropTypes.string,
        timeout: PropTypes.string,
        loading: PropTypes.bool,
    };

    handleFormChange = ({value, errors}) => {
        this.props.onChange(value, errors);
    };

    handleSend = () => {
        const {value} = this.props;
        const errors = schema.validate(value);
        if (noErrors(errors)) {
            this.props.onSave();
        }
        else {
            this.props.onChange(value, errors);
        }
    };

    render() {
        const {value, errors, timeout, source, showForgotPassword, loading} = this.props;
        return (
            <div>
                <Editor
                    onChange={this.handleFormChange}
                    schema={schema}
                    timeout={timeout}
                    loading={loading}
                    source={source}
                    value={value}
                    onSubmit={this.handleSend}
                    showForgotPassword={showForgotPassword}
                    errors={errors}/>
            </div>
        );
    }
}

export default Login;
