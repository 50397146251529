export function applyTexts(single, multiple, params) {
    if (!params) {
        params = multiple;
        multiple = single;
    }
    const phrase = params.count === 1 ? single : multiple;
    return Object.keys(params).reduce(
        (acc, key) => acc.replace(new RegExp(`{${key}}`, 'g'), params[key]),
        phrase,
    );
}
