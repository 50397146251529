import {createSelector} from 'reselect';

import {filteredForm} from './helpers';
import {isAirExchangeFlow} from 'airborne/air/store/exchange/selectors';


const restoreAirCheckoutForm = createSelector(
    (airBooking) => airBooking.checkoutForm.value,
    (value) => (value && [['set', 'airBooking.checkoutForm.value', filteredForm(value)]]),
);

export function isAirExchangeException(keyTuple, state) {
    if (!isAirExchangeFlow(state)) {
        return false;
    }

    return Boolean(keyTuple.find(key => {
        if (typeof key !== 'string') {
            return false;
        }

        return [
            'multipnr',
            'multiPnr',
            'dest',
            'homepage',
            'tspm',
            'air',
        ].some(name => key.startsWith(name));
    }));
}

export default [
    'air.fareGroups.data',
    'air.fareKeys',
    'air.flightPrice.data',
    'air.filters',
    'air.seatMap.data',
    'air.fareFamilies.data',
    'air.fareFamilies.selected',
    'air.policyRules',
    'airAvailability.flightOptions.data',
    ['airAvailability.selection', ['currentOD', 'flightOptionsSelection', 'currentPage']],
    ['airBooking.status', ['uuid', 'retry', 'bookingUid', 'confirmed']],
    'airBooking.separatedTicketsStatus',
    'airPricing.form',
    'airPricing.fares',
    ['airPricing.separatedTickets', ['separatedTicketsMode', 'currentOD', 'separatedFares']],
    'airBooking.checkoutData.data',
    'airBooking.skip',
    'airBooking.step',
    (state) => restoreAirCheckoutForm(state.airBooking),
];
