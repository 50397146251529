import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import settings from 'airborne/settings';

export const serializeDataCollection = (collection = []) => {
    if (isEmpty(collection)) {
        return [];
    }

    return collection.map(obj =>
        omitBy(obj, (prop) => isNil(prop) || prop === '')
    );
};

export function getSsoLanguages() {
    const {LANGUAGES} = settings;
    return [
        ...LANGUAGES,
        ['no', 'Norsk (NO)', 'no_NO'],
        ['sv', 'Svenska (SV)', 'sv_SV'],
        ['fr-ca', 'Français canadien (FR-CA)', 'fr_CA'],
    ];
}

export function getDisplayNames() {
    const LANGUAGES = getSsoLanguages();
    return LANGUAGES
        .filter(([lang])=> lang !== 'dummy')
        .map(([lang])=> ({'language': lang, 'name': ''}));
}

export function addDisplayNames(configs) {
    return configs.map(config => {
        const {display_names: displayNames} = config;
        return displayNames ? config : {...config, 'display_names': getDisplayNames()};
    });
}
