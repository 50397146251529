import {combineReducers} from 'redux';
import tickets from './tickets';
import voiding from './voiding';
import refund from './refund';

const airDocumentsManage = combineReducers({
    tickets,
    voiding,
    refund,
});

export default airDocumentsManage;
