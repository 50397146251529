import {createSelector} from 'reselect';
import chunk from 'lodash/chunk';
import omit from 'lodash/omit';
import gettext from 'airborne/gettext';
import {createAirlineChoices, createAllianceChoices} from 'airborne/air/fare_search/helpers';
import {applyAirAvailabilityFilters, createAvailabilityStopsChoices} from 'airborne/air/availability/helpers/filters';
import {addODsWithoutFlightOptions, getAvailabilityFlightOptionsData, getCurrentFlightOptions} from './flightOptions';
import {getCurrentODStep, getCurrentPage} from './selection';
import {PREFERRED_AIRLINES_CHOICES} from 'airborne/air/fare_search/helpers';
import {BCD_PREFERRED_LABEL, COMPANY_PREFERRED_LABEL} from 'airborne/air/fare_search/Filters/filterLabels';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {getAirDestValue} from 'airborne/air/store/confirmation/selectors';
import {AIR_TRIP_TYPES} from 'airborne/homepage2/types';

const PAGE_SIZE = 20;

export const getAirAvailabilityFilters = state =>
    state.airAvailability.filters;

export const getAirlineFilters = state => getAirAvailabilityFilters(state).airlines;

export const getAllianceFilters = state => getAirAvailabilityFilters(state).alliances;

export const getStopsChoices = createSelector(
    getCurrentFlightOptions,
    getAirAvailabilityFilters,
    getCurrentODStep,
    (flightOptions, filtersValue, currentOD) =>
        createAvailabilityStopsChoices(
            applyAirAvailabilityFilters(flightOptions || [], omit(filtersValue, 'stops'), currentOD)
        )
);

export const getAirlinesChoices = createSelector(
    getCurrentFlightOptions,
    getAirAvailabilityFilters,
    getCurrentODStep,
    (flightOptions, filtersValue, currentOD) => {
        if (!flightOptions) return [];

        const filteredFlightOptions = applyAirAvailabilityFilters(flightOptions, omit(filtersValue, 'airlines'), currentOD);
        return createAirlineChoices(flightOptions, filteredFlightOptions);
    }
);

export const getAllianceChoices = createSelector(
    getCurrentFlightOptions,
    getAirAvailabilityFilters,
    getCurrentODStep,
    (flightOptions, filtersValue, currentOD) => {
        if (!flightOptions) return [];

        const filteredFlightOptions = applyAirAvailabilityFilters(flightOptions, omit(filtersValue, 'alliances'), currentOD);
        return createAllianceChoices(filteredFlightOptions);
    }
);

export const getFlightNumberChoices = createSelector(
    getCurrentFlightOptions,
    getAirAvailabilityFilters,
    getCurrentODStep,
    (flightOptions, filtersValue, ODIndex) => {
        if (!flightOptions) return [];

        const filteredFlightOptions = applyAirAvailabilityFilters(flightOptions, omit(filtersValue, 'flightNumbers'), ODIndex);

        return filteredFlightOptions.reduce((acc, {segments}) =>
            [...acc, ...segments.map(({carrier}) => ({
                label: `${carrier.code}${carrier.flightNumber}`,
                ODIndex,
            }))],
        []);
    }
);

export const getOnlyAvailableChoices = createSelector(
    getCurrentFlightOptions,
    getAirAvailabilityFilters,
    (flightOptions, filtersValue) => {
        if (!flightOptions) return [];

        const filteredFlightOptions = applyAirAvailabilityFilters(flightOptions, omit(filtersValue, 'onlyAvailable'));
        const count = filteredFlightOptions.reduce((acc, cur) => cur.allSegmentsAvailable ? acc + 1 : acc, 0);

        return [
            [true, gettext('Hide sold-out flights'), count],
        ];
    }
);

export const getPreferenceChoices = createSelector(
    getCurrentFlightOptions,
    getAirAvailabilityFilters,
    (flightOptions, filtersValue) => {
        if (!flightOptions) return [];
        const {BCD_PREFERRED, COMPANY_PREFERRED} = PREFERRED_AIRLINES_CHOICES;

        const filteredFlightOptions = applyAirAvailabilityFilters(flightOptions, omit(filtersValue, 'onlyAvailable'));
        const countByKey = (key) => filteredFlightOptions.reduce((acc, flightOption) => flightOption[key] ? acc + 1 : acc, 0);

        return [
            [BCD_PREFERRED, BCD_PREFERRED_LABEL, countByKey(BCD_PREFERRED)],
            [COMPANY_PREFERRED, COMPANY_PREFERRED_LABEL, countByKey(COMPANY_PREFERRED)],
        ];
    }
);

const getFilteredFlightOptions = createSelector(
    getCurrentFlightOptions,
    getAirAvailabilityFilters,
    getCurrentODStep,
    (flightOptions, filters, currentOD) => (
        flightOptions && applyAirAvailabilityFilters(flightOptions, filters, currentOD)
    )
);

export const getVisibleFlightOptions = createSelector(
    getFilteredFlightOptions,
    getCurrentPage,
    (filteredFlightOptions, currentPage) =>
        filteredFlightOptions
            ? chunk(filteredFlightOptions, PAGE_SIZE)[currentPage - 1]
            : null
);

export const getTotalPages = state => {
    const flightOptions = getFilteredFlightOptions(state);

    return flightOptions ? Math.ceil(flightOptions.length / PAGE_SIZE) : 0;
};

export const makeTimeRangeSelector = (ODIndex, juncture) => createSelector(
    getAvailabilityFlightOptionsData,
    getAirDestValue,
    (data, destFormValue) => {
        const junctureMinutesKeys = {'departure': 'departureMinutes', 'arrival': 'arrivalMinutes'};
        const junctureKey = junctureMinutesKeys[juncture];
        const defaultRange = {min: 0, max: 24 * 60 - 1};
        if (!data || isEmpty(data)) return defaultRange;

        const originDestinations = destFormValue.tripType === AIR_TRIP_TYPES.MULTI_CITY
            ? addODsWithoutFlightOptions(destFormValue, data.originDestinations)
            : data.originDestinations;

        if (originDestinations.some(OD => isEmpty(OD.flightOptions))) {
            return defaultRange;
        }

        return originDestinations[ODIndex].flightOptions.reduce((optRange, optCur) => {
            const {[junctureKey]: duration} = optCur;
            const {min: curMin, max: curMax} = optRange;
            return {min: Math.min(curMin, duration), max: Math.max(curMax, duration)};
        },  {min: Infinity, max: 0});
    }
);

export const makeSelectedAvailabilityTimeRangeSelector = (ODIndex, juncture) => {
    const getTimeRange = makeTimeRangeSelector(ODIndex, juncture);
    return createSelector(
        getAirAvailabilityFilters,
        getTimeRange,
        (filters, timeRange) => {
            const {
                min: initMin = 0,
                max: initMax = 24*60-1
            } = get(filters, ['time', ODIndex, juncture], {});
            const {min: rangeMin, max: rangeMax} = timeRange;
            return {min: Math.max(initMin, rangeMin), max: Math.min(initMax, rangeMax)};
        }
    );
};
