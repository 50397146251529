import gettext from 'airborne/gettext';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';

export const RESTRICTED_COUNTRY_ERROR_CODE = 1076000;

const OPTIONAL_SERVICE_KEYS = 1063173;

const OPTIONAL_SERVICES_ERRORS = [
    gettext('<b>Warning!</b> Some of the requested Optional Services are not available.'),
    gettext('You can go back to the \u0022Optional Services\u0022 step to select again or proceed to confirmation.'),
    gettext('<b>If you proceed now, the optional services will not be applied.</b>')
];

const DEFAULT_PRICE_UPDATE_ERROR = [
    gettext('<b>Warning!</b> Error occurred during repricing with optional services. Selected optional services can not be confirmed.'),
    gettext('<b>If you proceed now, the optional services will not be applied.</b>'),
];

const DEFAULT_UNRECOVERABLE_ERROR = [
    gettext('<b>Warning!</b> Error occurred during repricing. Selected options hasn\'t been applied.'),
    gettext('<b>You can\'t proceed now.</b> Please, try to select alternative options.'),
];

export function isOptionalServiceKeysError(error) {
    return error.error_code === OPTIONAL_SERVICE_KEYS;
}

function getErrorMessage(error) {
    if (isOptionalServiceKeysError(error)) return OPTIONAL_SERVICES_ERRORS;
    return DEFAULT_PRICE_UPDATE_ERROR;
}

function getErrors(response) {
    return response.body?.errors || [];
}

function hasErrorWithCode(response, errorCode) {
    return getErrors(response).some(error => error.error_code === errorCode);
}

export function isRestrictedCountryError(response) {
    return hasErrorWithCode(response, RESTRICTED_COUNTRY_ERROR_CODE);
}

export function apiError(response, unrecoverable) {
    try {
        const errors = getErrors(response);
        const messages = compact(flatten(errors.map(getErrorMessage)));
        return messages.length === 0 ? null : messages;
    }
    catch {
        return unrecoverable ? DEFAULT_UNRECOVERABLE_ERROR : DEFAULT_PRICE_UPDATE_ERROR;
    }
}
