// It is temporary, we never had state translations
// We will return it shortly
// import gettext from 'airborne/gettext';
const gettext1 = (text) => text;

const getStates = (country) => ({
    'AU': {
        'ACT': gettext1('Australian Capital Territory'),
        'NSW': gettext1('New South Wales'),
        'NT': gettext1('Northern Territory'),
        'QLD': gettext1('Queensland'),
        'SA': gettext1('South Australia'),
        'TAS': gettext1('Tasmania'),
        'VIC': gettext1('Victoria'),
        'WA': gettext1('Western Australia'),
    },
    'CA': {
        'AB': gettext1('Alberta'),
        'BC': gettext1('British Columbia'),
        'MB': gettext1('Manitoba'),
        'NB': gettext1('New Brunswick'),
        'NL': gettext1('Newfoundland and Labrador'),
        'NT': gettext1('Northwest Territories'),
        'NS': gettext1('Nova Scotia'),
        'NU': gettext1('Nunavut'),
        'ON': gettext1('Ontario'),
        'PE': gettext1('Prince Edward Island'),
        'QC': gettext1('Quebec'),
        'SK': gettext1('Saskatchewan'),
        'YT': gettext1('Yukon'),
    },
    'US': {
        'AL': gettext1('Alabama'),
        'AK': gettext1('Alaska'),
        'AZ': gettext1('Arizona'),
        'AR': gettext1('Arkansas'),
        'CA': gettext1('California'),
        'CO': gettext1('Colorado'),
        'CT': gettext1('Connecticut'),
        'DE': gettext1('Delaware'),
        'DC': gettext1('District of Columbia'),
        'FL': gettext1('Florida'),
        'GA': gettext1('Georgia'),
        'HI': gettext1('Hawaii'),
        'ID': gettext1('Idaho'),
        'IL': gettext1('Illinois'),
        'IN': gettext1('Indiana'),
        'IA': gettext1('Iowa'),
        'KS': gettext1('Kansas'),
        'KY': gettext1('Kentucky'),
        'LA': gettext1('Louisiana'),
        'ME': gettext1('Maine'),
        'MD': gettext1('Maryland'),
        'MA': gettext1('Massachusetts'),
        'MI': gettext1('Michigan'),
        'MN': gettext1('Minnesota'),
        'MS': gettext1('Mississippi'),
        'MO': gettext1('Missouri'),
        'MT': gettext1('Montana'),
        'NE': gettext1('Nebraska'),
        'NV': gettext1('Nevada'),
        'NH': gettext1('New Hampshire'),
        'NJ': gettext1('New Jersey'),
        'NM': gettext1('New Mexico'),
        'NY': gettext1('New York'),
        'NC': gettext1('North Carolina'),
        'ND': gettext1('North Dakota'),
        'OH': gettext1('Ohio'),
        'OK': gettext1('Oklahoma'),
        'OR': gettext1('Oregon'),
        'PA': gettext1('Pennsylvania'),
        'RI': gettext1('Rhode Island'),
        'SC': gettext1('South Carolina'),
        'SD': gettext1('South Dakota'),
        'TN': gettext1('Tennessee'),
        'TX': gettext1('Texas'),
        'UT': gettext1('Utah'),
        'VT': gettext1('Vermont'),
        'VA': gettext1('Virginia'),
        'WA': gettext1('Washington'),
        'WV': gettext1('West Virginia'),
        'WI': gettext1('Wisconsin'),
        'WY': gettext1('Wyoming'),
    },
})[country] || {};

export default getStates;
