/* global global */
/* eslint-disable import/no-unresolved */
/* eslint-disable immutable/no-mutation */
import i18next from 'i18next';
import moment from 'moment';
import {initReactI18next} from 'react-i18next';

import dummyForTests from 'airborne/../translations/dummyForTests.json';
import en from 'airborne/../translations/en.json';
import settings from 'airborne/settings';

const i18nCallback = (err) => {
    if (err) {
        console.error('Something went wrong loading translations:', err); // eslint-disable-line no-console
    }
};

const testConfig = process.env.NODE_ENV === 'test'
    ? {
        lng: 'en',
        resources: {
            en: {
                translation: dummyForTests,
            },
        },
    } : {};

i18next
    .use(initReactI18next)
    .init({
        lng: settings.LANGUAGE_CODE,
        fallbackLng: 'en',
        debug: process.env.NODE_ENV === 'development', // eslint-disable-line no-undef
        keySeparator: false,
        nsSeparator: false,
        interpolation: {
            prefix: '{',
            suffix: '}',
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
        returnEmptyString: false,
        fallbackNS: 'translation',
        resources: {
            en: {
                translation: en,
            },
        },
        saveMissingPlurals: false,
        ...testConfig,
    }, process.env.REACT_APP_DEBUG ? i18nCallback : undefined); // eslint-disable-line no-undefined

moment.locale(settings.LANGUAGE_CODE);
const t = i18next.t.bind(i18next);
global.i18next = i18next;
export default t;

export {t as gettext};
