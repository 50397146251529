import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import uniq from 'lodash/uniq';
import omit from "lodash/omit";

function isQualifierDefined({value}) {
    return isBoolean(value) || !isEmpty(value) || isNumber(value);
}

function serializeQualifiers(qualifiers) {
    return qualifiers
        .map(qualifier => {
            if (qualifier.field === 'is_refundable') {
                return {...qualifier, opposite: !qualifier.value, value: true};
            }
            if (['cabin_class_all_segments', 'cabin_class_any_segment'].includes(qualifier.field)) {
                const {'cabin_class': cabinClass, restriction} = qualifier.value;

                return {
                    ...omit(qualifier, ['restriction']),
                    value: cabinClass,
                    ...(restriction ? {restriction} : {})
                };
            }
            return qualifier;
        })
        .filter(isQualifierDefined);
}

function serializeTranslatedFields({'rule_title': title = {}, 'rule_description': description = {}}) {
    const usedLangs = uniq([...Object.keys(title), ...Object.keys(description)]);

    return usedLangs
        .filter((language) => !isEmpty(title[language]))
        .map((language) => ({
            language,
            title: title[language],
            description: description[language],
        }));
}

function removeEmpty(rules) {
    return rules && rules
        .map((value) => {
            const {
                'rule_name': ruleName,
                qualifiers,
                unbookable = false,
                'hide_out_of_policy': hideOutOfPolicy = false
            } = value;

            const ruleTranslations = serializeTranslatedFields(value);

            return {
                'qualifiers': serializeQualifiers(qualifiers),
                'rule_name': ruleName || 'New Air Policy Rule',
                unbookable,
                'hide_out_of_policy': hideOutOfPolicy,
                ...(
                    ruleTranslations.length
                        ? {'rule_translations': ruleTranslations}
                        : {}
                ),
            };
        })
        .filter(({qualifiers}) => qualifiers.length);
}


export function serialize(data) {
    return {
        ...data,
        ['air:air_policy:rules']: removeEmpty(data['air:air_policy:rules'])
    };
}

function parseQualifiers(qualifiers) {
    return qualifiers
        .map(qualifier => {
            if (qualifier.field === 'is_refundable') {
                return {...qualifier, opposite: false, value: !qualifier.opposite};
            }
            if (['cabin_class_all_segments', 'cabin_class_any_segment'].includes(qualifier.field)) {
                return {
                    ...qualifier,
                    value: {'cabin_class': qualifier.value, restriction: qualifier.restriction}
                };
            }
            return qualifier;
        });
}

function parseTranslatedField(ruleTranslations = [], fieldName) {
    return Object.fromEntries(
        ruleTranslations
            .map((ruleTranslation) => [
                    ruleTranslation.language,
                    ruleTranslation[fieldName]
                ]
            )
    );
}

function parseRules(rules) {
    return rules && rules
        .map((value) => {
            const {
                'rule_name': ruleName,
                qualifiers,
                unbookable,
                'hide_out_of_policy': hideOutOfPolicy,
                'rule_translations': ruleTranslations
            } = value;

            return {
                'rule_name': ruleName,
                unbookable,
                'hide_out_of_policy': hideOutOfPolicy,
                'qualifiers': parseQualifiers(qualifiers),
                'rule_title': parseTranslatedField(ruleTranslations, 'title'),
                'rule_description': parseTranslatedField(ruleTranslations, 'description'),
            };
        })
}

export function parse(data) {
    return {
        ...data,
        ['air:air_policy:rules']: parseRules(data['air:air_policy:rules']),
    }
}
