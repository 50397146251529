import capitalize from 'lodash/capitalize';
import unescape from 'lodash/unescape';
import isNumber from 'lodash/isNumber';
import gettext from 'airborne/gettext';
import settings from 'airborne/settings';
import {parseRecentAirAutocomplete} from './recentAir';
import {formatUrl} from 'midoffice/helpers/url';
import moment from "moment/moment";

export const TRAVELPORT_GDS = ['apollo', 'galileo'];
const API_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';

const ICONS = {
    'a': 'airplane',
    'ar': 'compass',
    'b': 'bus',
    'c': 'building',
    'cl': 'home',
    'h': 'hotel',
    'l': 'landmark',
    't': 'train',
    'p': 'boat',
};
function iconForType(type) {
    return ICONS[type] || null;
}

export const parseDatetime = (datetime)=> (
    moment(datetime, API_DATETIME_FORMAT).format()
);

function parseList(list, category, id) {
    if (!list) {
        return null;
    }
    const items = list.map((
        {
            code,
            label,
            type,
            'iata_code': iataCode,
            'latitude': lat,
            'longitude': lon
        },
        index,
    ) => ({
        value: code,
        label,
        iataCode,
        type,
        icon: iconForType(type),
        lat: isNumber(lat) ? lat : null,
        lon: isNumber(lon) ? lon : null,
        head: index === 0,
    }));
    return {items, category, id};
}

function searchTerm(query) {
    return {
        value: null,
        label: query,
        icon: 'search',
        type: 'search',
    };
}

export function parseDestinations(query, {'match_data': {cities, 'airports_and_stations': airports, hotels, landmarks, 'company_locations': corporate}, order}) {
    const parseData = {
        cities: parseList(cities, gettext('Cities/Areas'), 'c'),
        'airports_and_stations': parseList(airports, gettext('Airports/Stations'), 'a'),
        hotels: parseList(hotels, gettext('Hotels', {count: 2}), 'h'),
        landmarks: parseList(landmarks, gettext('Landmarks'), 'l'),
        'company_locations': parseList(corporate, gettext('Company Locations'), 'cl'),
    };
    const travelPortOnlyAirports = airports?.filter(airport => airport.type === 'a');
    const travelPortData = {
        'airports_and_stations': parseList(travelPortOnlyAirports, gettext('Airports'), 'a'),
    };
    const data = this?.gds && TRAVELPORT_GDS.includes(this.gds) ? travelPortData : parseData;
    const ret = [];
    order.forEach((key)=> {
        if (data[key] && data[key].items.length) ret.push(data[key]);
    });
    query && ret.push(searchTerm(query));
    return ret.length ? ret : null;
}

function parseRecentList(list) {
    return list.map(({destination, id, checkin, checkout, expired, chains, keywords, distance, type})=> ({
        value: destination,
        label: unescape(id),
        checkin, checkout, expired,
        chains: chains || null,
        keywords: keywords || null,
        icon: iconForType(type),
        distance,
        type,
    }));
}

export function parseRecent({bookings, searches}) {
    const data = {
        bookings: bookings && {items: parseRecentList(bookings, 'landmark'), category: gettext('Recent Bookings'), id: 'b'},
        searches: searches && {items: parseRecentList(searches, 'landmark'), category: gettext('Recent Searches'), id: 's'},
    };
    const ret = [];
    if (data.searches) { ret.push(data.searches); }
    if (data.bookings) { ret.push(data.bookings); }

    return ret.length ? ret : null;
}

function processId(id, type) {
    if (type === 'a' && id.startsWith('Region:')) {
        return {iataCode: id.split('Region:')[1]};
    }
    if (id.startsWith('Coordinates:')) {
        const [lat, lon] = id.split('Coordinates:')[1].split(',');
        return {lat, lon};
    }

    return {};
}

function buildLocation(search, prefix) {
    const {
        [`${prefix}_location`]: location,
        [`${prefix}_location_type`]: type,
        [`${prefix}_location_name`]: name,
    } = search;

    return {
        ...processId(location, type),
        value: location,
        type,
        label: name,
        icon: iconForType(type),
    };
}

export function parseRecentCars({'car_recent_searches': list}) {
    return list.length ? list.map((s)=> ({
        ...buildLocation(s, 'pickup'),

        dropOff: s['different_dropoff'] ? buildLocation(s, 'dropoff') : null,
        expired: s.expired,
        pickUpDistance: s['pickup_search_radius'],
        dropOffDistance: s['different_dropoff'] ? s['dropoff_search_radius'] : null,
        dates: {
            min: parseDatetime(s['pickup_datetime']),
            max: parseDatetime(s['dropoff_datetime']),
        },
        differentDropOff: s['different_dropoff'],
        acriss: s['car_types'],

        recentItem: true,
    })) : null;
}

export function parseRecentAir({'air_recent_searches': list}) {
    return parseRecentAirAutocomplete(list);
}

function parseCompany(item) {
    const system = settings.SUB_PARTNER;
    const {
        id: value,
        title: label,
        smid,
        'parent_title': parentTitle,
        'tspm_entity_id': tspmId,
        'obt_client_id': obtClientId,
        'sap_account_number': sapAccountNumber,
        'gcn_id': gcnId,
        'primary_gds': primaryGds,
        'agency_type_name': agencyType,
        'country_of_origin_name': countryName,

    } = item;
    const gdsName = capitalize(primaryGds || item[`${system}.primary_gds`]);
    const gdsOid = item['gds_oid'] || item[`${system}.gds_oid`];

    return {
        value,
        label,
        parentLabel: parentTitle,
        tspmId,
        gdsName,
        gdsOid,
        obtClientId,
        gcnId,
        sapAccountNumber,
        agencyType,
        countryName,
        smid,
    };
}

export function parseCompanyList(data) {
    return data.map(parseCompany);
}

export function nopAborted({request, status}) {
    if (request.isAborted) {
        throw new Object({ignore: true});
    }

    if (status === 401) {
        setTimeout(() => {
            location.assign(formatUrl('/accounts/login/', {next: location.pathname + location.search}));
        }, 1000);

        return gettext('You do not have permission to perform this action');
    }
    return gettext('Server error');
}

export function getQueryAutocompleteOption(query, items) {
    if (!items?.length) {
        return null;
    }

    const matchedItems = items.filter(item => item.iataCode === query.toUpperCase());
    const airport = matchedItems.find(item => item.type === 'a');

    if (airport) {
        return airport;
    }

    return matchedItems[0];
}

export function onKeyUpSlash(event) {
    const isActiveInput = (
        document?.activeElement?.classList?.value?.includes('form-control') || document?.activeElement?.tagName === 'TEXTAREA'
        || document?.activeElement?.classList?.value?.includes('Draft') || document?.activeElement?.tagName === 'INPUT'
    );

    if (event.code === 'Slash' && !isActiveInput) {
        const input = document.querySelector('#mainInputOnPage');
        input && input.focus();
    }
}
