import {register} from 'airborne/types';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import normalize from 'airborne/search2/helpers/normalize';


const CHECKOUT_FIELDS = [
    'billing_number',
    'billing_reference',
    'it_number',
    'email',
    'first_name',
    'last_name',
    'special_information',
    'credit_cards',
    'crd',
    'vpa_card_pools',
    'phone',
];

function parse(data) {
    return {
        ...pickBy(pick(data, CHECKOUT_FIELDS)),
        creditCards: normalize(data.credit_cards),
    };
}

register('carCheckoutData', {parse});
