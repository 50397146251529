import {combineReducers} from 'redux';
import union from 'lodash/union';
import isNil from 'lodash/isNil';
import * as checkoutTypes from '../actionTypes';
import * as searchTypes from 'airborne/air/store/fare_search/actionTypes';
import * as exchangeTypes from 'airborne/air/store/exchange/actionTypes';
import 'airborne/air/checkout/types';
import {toStep, stepAfter, stepBefore} from 'airborne/air/checkout/helpers/steps';
import {isSameSeats} from 'airborne/air/checkout/helpers/seatSelection';
import {applyPatch} from 'midoffice/newforms/helpers';
import bookProgress from './bookProgress';
import {TRAVELER_NUMBER_COUNTRIES} from 'airborne/air/checkout/helpers/checkoutForm';
import {TABS as TRAVEL_DOC_TABS} from 'airborne/air/checkout/TravelDocumentation/PassportSchema';

export const INITIAL_FAKE_BOOKING = {
    fakeBooking: false,
    status: 'Succeeded',
    delay: 0,
    ccVerification: false,
};

const INITIAL_CHECKOUT_DATA = {
    data: {
        0: {
            'cvv_required': false,
            'billing_address_required': false,
            'phone_number_required': false
        }
    },
    loading: false,
    noTspmAccess: false,
};

const INITIAL_CHECKOUT_FORM = {
    value: {
        ...INITIAL_FAKE_BOOKING,
        ftNumbers: [],
        'traveler_numbers': [
            {
                'country_valid_for': TRAVELER_NUMBER_COUNTRIES.US,
            }
        ],
        travelDocumentationTab: TRAVEL_DOC_TABS.NOT_APPLICABLE,
    },
    errors: null,
};

function checkoutData(state, {type, checkoutData, ticketIndex = 0}) {
    if (!state) {
        return INITIAL_CHECKOUT_DATA;
    }
    if (type === checkoutTypes.AIR_CHECKOUT_DATA_LOADING) {
        return {...state, loading: true};
    }
    if (type === checkoutTypes.AIR_CHECKOUT_DATA_FAIL) {
        return {
            ...state,
            data: {...state.data, [ticketIndex]: null},
            loading: false,
            noTspmAccess: true,
        };
    }
    if (type === checkoutTypes.AIR_CHECKOUT_DATA_LOADED) {
        return {
            ...state,
            data: {...state.data, [ticketIndex]: checkoutData},
            loading: false,
            noTspmAccess: false,
        };
    }
    if (type === 'TRAVELER_PROFILE_CHANGED' || type === 'LOADED_PNR' || type === 'NO_PNR') {
        return INITIAL_CHECKOUT_DATA;
    }
    return state;
}

function step(state, {type, step, stepsToSkip}) {
    if (!state || type === searchTypes.AIR_TO_CHECKOUT) {
        return 'general';
    }

    if (type === checkoutTypes.AIR_TO_PREV_CHECKOUT_STEP) {
        return stepBefore(state, stepsToSkip);
    }

    if (type === checkoutTypes.AIR_TO_NEXT_CHECKOUT_STEP) {
        return stepAfter(state, stepsToSkip);
    }

    if (type === checkoutTypes.AIR_TO_CHECKOUT_STEP) {
        return toStep(state, step);
    }

    return state;
}

function checkoutForm(state, {type, toChange, toRemove}) {
    if (!state) {
        return INITIAL_CHECKOUT_FORM;
    }

    if (type === checkoutTypes.AIR_PATCH_CHECKOUT_FORM) {
        return {
            ...state,
            ...applyPatch({value: state.value, errors: state.errors, toChange, toRemove}),
        };
    }

    if (type === searchTypes.AIR_CHECKOUT_LOADING) {
        const ccFields = Object.keys(state.value).filter(key => key.startsWith('credit_card_'));
        return {
            ...state,
            ...applyPatch({
                value: state.value,
                errors: state.errors,
                toChange: {},
                toRemove: ['optional_services', ...ccFields],
            }),
        };
    }

    if (type === 'TRAVELER_PROFILE_CHANGED' || type === 'LOADED_PNR' || type === 'NO_PNR') {
        return INITIAL_CHECKOUT_FORM;
    }

    return state;
}

const defaultStatus = {
    uuid: null,
    retry: 0,
    bookingUid: null,
    loading: false,
    message: null,
    recoverable: null,
    confirmed: false,
};

function status (state, {
    type, uuid, bookingUid, message, recoverable, errorCode, extra, initialFareDetails, ticketIndex
}) {
    if (!state) {
        return defaultStatus;
    }
    if (!isNil(ticketIndex)) {
        return state;
    }
    if (type === checkoutTypes.AIR_BOOK_LOADED) {
        return {
            ...state,
            bookingUid,
        };
    }
    if (type === checkoutTypes.AIR_TO_CONFIRM) {
        return {
            ...state,
            loading: false,
            confirmed: true,
        };
    }
    if (type === checkoutTypes.AIR_BOOK_LOADING) {
        return {
            ...state,
            loading: true,
            message: null,
            recoverable: null,
        };
    }
    if (type === checkoutTypes.AIR_BOOK_FAIL) {
        return {
            ...state,
            loading: false,
            retry: state.retry + 1,
            message,
            recoverable,
            errorCode,
            extra,
            initialFareDetails,
        };
    }
    if ([
        searchTypes.AIR_TO_CHECKOUT,
        exchangeTypes.AIR_EXCHANGE_OPEN,
    ].includes(type)) {
        return {
            uuid,
            retry: 0,
            bookingUid: null,
            loading: false,
            message: null,
            recoverable: null,
            confirmed: false,
        };
    }
    return state;
}

function separatedTicketsStatus (state, {
    type, uuid, bookingUid, message, recoverable, errorCode, extra, initialFareDetails, ticketIndex, pnr
}) {
    if (!state) {
        return {};
    }
    if (isNil(ticketIndex)) {
        return state;
    }
    if (type === checkoutTypes.AIR_BOOK_LOADED) {
        return {
            ...state,
            [ticketIndex]: {
                ...state[ticketIndex],
                bookingUid
            }
        };
    }
    if (type === checkoutTypes.AIR_TO_CONFIRM) {
        return {
            ...state,
            [ticketIndex]: {
                ...state[ticketIndex],
                loading: false,
                confirmed: true,
                pnr,
            }
        };
    }
    if (type === checkoutTypes.AIR_BOOK_LOADING) {
        return {
            ...state,
            [ticketIndex]: {
                ...state[ticketIndex],
                loading: true,
                message: null,
                recoverable: null,
            }
        };
    }
    if (type === checkoutTypes.AIR_BOOK_FAIL) {
        return {
            ...state,
            [ticketIndex]: {
                ...state[ticketIndex],
                loading: false,
                retry: state.retry + 1,
                message,
                recoverable,
                errorCode,
                extra,
                initialFareDetails,
            }
        };
    }

    if (type === searchTypes.AIR_TO_CHECKOUT) {
        return {
            ...state,
            [ticketIndex]: {
                ...defaultStatus,
                uuid,
            }
        };
    }

    return state;
}

function currentTicket (state = null, {type, ticketIndex}) {
    if (type === checkoutTypes.AIR_BOOK_SET_TICKET) {
        return ticketIndex;
    }

    return state;
}

function skip (state, {type, fields, screens}) {
    if (!state) {
        return {
            fields: [],
            screens: [],
        };
    }

    if (type === checkoutTypes.AIR_CHECKOUT_SKIP_ADD) {
        return {
            ...state,
            fields: union(state.fields, fields),
            screens: union(state.screens, screens),
        };
    }
    if (type === checkoutTypes.AIR_CHECKOUT_SKIP_REMOVE) {
        return {
            fields: state.fields.filter(field => !fields.includes(field)),
            screens: state.screens.filter(screen => !screens.includes(screen))
        };
    }

    return state;
}


const initialSeatSelection = [];
const seatSelection = (
    state = initialSeatSelection,
    {type, seat, index, segmentIdRef, ticketIndex}) => {

    if (type === checkoutTypes.AIR_ADD_SEAT_TO_SEAT_SELECTION) {
        return  [...state, seat];
    }

    if (type === checkoutTypes.AIR_REMOVE_SEAT_FROM_SELECTION) {
        return state.filter(currentSeat => !isSameSeats(currentSeat, seat));
    }

    if (type === checkoutTypes.AIR_REPLACE_LAST_SEAT_IN_SELECTION) {
        return Object.assign([], state, {[index]: seat});
    }

    if (type === checkoutTypes.AIR_CLEAR_SEAT_SELECTION) {
        return state.filter(currentSeat => {
            const isNotSameSegment = currentSeat.segmentIdRef !== segmentIdRef;
            return isNil(ticketIndex)
                ? isNotSameSegment
                : isNotSameSegment || currentSeat.ticketIndex !== ticketIndex;
        });
    }

    if (type === checkoutTypes.AIR_CLEAR_TICKET_SEAT_SELECTION) {
        return state.filter(currentSeat => currentSeat.ticketIndex !== ticketIndex);
    }

    if (
        type === searchTypes.AIR_TO_SEARCH_RESULT ||
        type === checkoutTypes.AIR_CLEAR_ALL_SEAT_SELECTION
    ) {
        return initialSeatSelection;
    }

    return state;
};

const initialBookingExtra = {};
const bookingExtra = (state = initialBookingExtra, {type, payload}) => {
    switch (type) {
        case checkoutTypes.AIR_BOOK_SET_BOOKING_EXTRA:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};


const airBooking = combineReducers({
    checkoutData,
    checkoutForm,
    step,
    status,
    separatedTicketsStatus,
    skip,
    seatSelection,
    bookingExtra,
    bookProgress,
    currentTicket,
});

export default airBooking;
