import gettext from 'airborne/gettext';
import pick from 'lodash/pick';
import moment from 'moment';
import find from 'lodash/find';

import emailValidator from 'midoffice/newforms/validators/email';
import {getCarsPaymentPriority, getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile.js';
import {getCarBooking, getCheckoutData, getSelectedRate} from 'airborne/cars/checkout/selectors';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import {prefillCrd} from 'airborne/checkout2/helpers/checkoutForm';
import keyBy from 'lodash/keyBy';
import partial from 'lodash/partial';
import {isCrdKey, matchConditions} from 'airborne/checkout2/helpers/crd';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import settings from 'airborne/settings';
import {
    getCarModificationFlowBooking,
    getCarModificationFlowBool
} from "midoffice/car-bookings/selectors";


export const CARS_CARDS = ['VI', 'AX', 'MC', 'DS', 'JC', 'DC', 'TP'];

const CHECKOUT_DATA_FIELDS = [
    'first_name',
    'last_name',
    'billing_number',
    'billing_reference',
    'special_information',
    'it_number',
    'vpa_card_pools'
];

const CHECKOUT_DATA_OVER_PNR_FIELDS = [
    'email',
];

function pickPnrFields(pnr) {
    return dropEmpty({
        'first_name': pnr['pnr_name_first'] || null,
        'last_name': pnr['pnr_name_last'] || null,
        'email': pnr['email'] || null,
    });
}

function prefillManualCard(value) {
    return {
        'credit_card_first_name': value['first_name'],
        'credit_card_last_name': value['last_name'],
    };
}

function prefillTspmCard(tspmCards, cardIndex) {
    const card = find(tspmCards, {cardIndex});
    return {
        'card_index': card.cardIndex,
        'credit_card_expiration': {
            year: Number(card.cardYear),
            month: Number(card.cardMonth),
        },
        'credit_card_first_name': card.firstName,
        'credit_card_last_name': card.lastName,
    };
}

function prefillCreditCard(value, checkoutData) {
    const tspmCards = checkoutData && checkoutData.creditCards;
    const tspmCardsPresent = tspmCards && tspmCards.length;
    let cardIndex = value && value.card_index;
    if (!value.hasOwnProperty('card_index')) {
        cardIndex = tspmCardsPresent ? tspmCards[0].cardIndex : null;
    }
    return (tspmCardsPresent && cardIndex)
        ? prefillTspmCard(tspmCards, cardIndex)
        : prefillManualCard(value);
}


export function filterCrd(state, value) {
    const rate = getSelectedRate(state);
    const checkoutData = getCheckoutData(state);
    const crd = checkoutData?.crd || [];
    const crdKeyed = keyBy(crd, 'code');
    const matchFn = partial(matchConditions, rate, value['paid_by'], value['evoucher']);
    return pickBy(
        crd.reduce(prefillCrd, value),
        (value, key)=> !isCrdKey(key) || matchFn(crdKeyed[key] || {})
    );
}
function defaultVpaPayment(checkoutData, value) {
    if (!checkoutData || (value.paid_by && value.paid_by !== 'vpa_payment')) {
        return value;
    }
    const {'vpa_card_pools': cardPools} = checkoutData;

    // Pre-fill card pool dropdown on checkout when company has single card pool
    const defaultValues = {
        'vpa_card_pool_id': !isEmpty(cardPools) && (cardPools.length === 1) && cardPools[0]['vpa_client_id'] || null,
    };
    return {
        ...defaultValues,
        ...value,
    };
}

function getEvoucher(paymentOptions, paymentPriority) {
    if (!paymentOptions?.includes('evoucher_voucher')) return {};
    return {
        'evoucher': paymentPriority.indexOf('voucher') > paymentPriority.indexOf('evoucher_voucher')
    };
}

export function getPaidBy(paymentPriority, paymentOptions) {
    const evoucherOptions = {
        'evoucher_voucher': 'voucher',
        'evoucher_iata': 'iata',
        'evoucher_card': 'card',
        'evoucher_vpa_guarantee': 'vpa_guarantee',
    };

    const paidBy = find(paymentPriority, (item) => paymentOptions.includes(item));
    return {
        'paid_by': evoucherOptions[paidBy] || paidBy,
        evoucher: Boolean(evoucherOptions[paidBy]),
    };
}

function prefillEmail(state) {
    const activeCarsBookingModificationFlow = getCarModificationFlowBool(state) || null;
    const email = getCarModificationFlowBooking(state)?.traveler?.email;
    return activeCarsBookingModificationFlow ? {email} : {};
}

export function prefill(state) {
    const pnr = getPnrProfile(state, 0);
    const checkoutData = getCheckoutData(state);

    const rate = getSelectedRate(state);
    const paymentOptions = rate ? rate.paymentOptions : ['card', 'no_payment'];
    const {value} = getCarBooking(state);
    const paymentPriority = getCarsPaymentPriority(state) || settings.CARS_USED_PAYMENT_OPTIONS_LABELS.map(option => option[0]);

    const prefilled = {
        ...pick(checkoutData, CHECKOUT_DATA_FIELDS),
        ...pickPnrFields(pnr),
        ...pick(checkoutData, CHECKOUT_DATA_OVER_PNR_FIELDS),
        ...getPaidBy(paymentPriority, paymentOptions),
        ...defaultVpaPayment(checkoutData, value),
        ...getEvoucher(paymentOptions, paymentPriority),
        ...prefillEmail(state),
        paymentPriority,
    };
    const filterCRD = filterCrd(state, {...prefilled,...value});
    return {
        ...prefilled,
        ...filterCRD,
        ...prefillCreditCard({...prefilled, ...value}, checkoutData),
    };
}

export function checkoutForm(state) {
    const {value, errors} = getCarBooking(state);
    return {
        value: {
            ...prefill(state),
            ...value,
        },
        errors,
    };
}

function expired() {
    return gettext('Please check your credit card expiration date. Credit card must not be expired.');
}

export function isCardExpirationValid({year, month}) {
    // In case there is no data provided, let user enter it
    if (!year || !month) {
        return true;
    }
    // javascipt enumerates months from zero, so - 1
    const expirationDate = moment([year, month - 1])
        .endOf('month');
    const now = moment();
    return expirationDate.isAfter(now);
}

export function validateExpiration(value) {
    return isCardExpirationValid(value)
        ? null
        : expired();
}

function getTspmEmail(state) {
    const profile = getPnrProfile(state, 0);
    return profile ? profile['tspm_email'] : null;
}

export function getTspmPhone(state) {
    const checkoutData = getCheckoutData(state);
    return checkoutData ? checkoutData.phone : null;
}

// Check is there TSPM email and is it correct, because checkoutData has email without indication its source (tspm, pnr,
// pofile)
function hasCorrectTspmEmail(state) {
    const tspmEmail = getTspmEmail(state);
    const checkoutData = getCheckoutData(state);
    const email = checkoutData && checkoutData.email;
    return Boolean(tspmEmail) && emailValidator()(tspmEmail) === null && tspmEmail === email;
}

export function isReadonlyEmail(state) {
    return hasCorrectTspmEmail(state);
}

export function isSpecialInformationPrefilled(state) {
    const checkoutData = getCheckoutData(state);
    return Boolean(checkoutData && checkoutData.special_information);
}
