export enum Step {
    pnr = 'pnr',
    dest = 'dest',
    search = 'search',
    checkout = 'checkout',
    aftBookingRecordsAir = 'aft_booking_records_air',
    emailOffers = 'email_offers',
    myBookings = 'my_bookings',
    aftBookingRecords = 'aft_booking_records',
    aftBookingRecordsCars = 'aft_booking_records_cars',
    aftExternalBookingRecordsAir = 'aft_external_booking_records_air',
    termsConditions = 'terms_conditions',
    settings = 'settings',
    airAvailability = 'air_availability',
    airPricing = 'air_pricing',
    directSell = 'direct_sell',
}


export interface HomepageState {
    step: Step | null,
    isPnrReady: boolean,
    destination: any,
    sso: any,
    search: any,
    companyWithoutPnrOpen: boolean,
}
