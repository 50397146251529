export {reuseDestination, selectDestination} from './destmodal';
export {retrievePNR, noPNR, discardPNR, formChangePNR} from './pnr';

import {loadConfigurationIds} from 'airborne/store/modules/homepage/actions/tspm';

export function showAlert(message, type='success') {
    return {type: 'ALERT_SHOW', messages: [
        {html: message, type}
    ]};
}

export function showQuickAlert(message, type='success') {
    return {
        type: 'ALERT_SHOW',
        messages: [
            {html: message, type}
        ],
        closeTimeout: 2,
    };
}

export function changeDestination({value, errors}) {
    return async function changeDestinationD(dispatch, getState) {
        dispatch({type: 'CHANGE_DEST', value, errors, index: 'all'});
        return dispatch(loadConfigurationIds());
    };
}

export function changeProduct(product) {
    return {type: 'CHANGE_PRODUCT', product};
}

export function toggleCollapsableSection(field, fieldState) {
    return {type: 'DEST_TOGGLE_COLLAPSABLE_SECTIONS', field, fieldState};
}

export function cleanupPrevProduct() {
    return {type: 'CLEANUP_PREV_PRODUCT'};
}

export function airChangeSearchType(searchType) {
    return {
        type: 'AIR_CHANGE_SEARCH_TYPE',
        searchType,
    };
}

export function carsChangeSearchType(searchType) {
    return {
        type: 'CARS_CHANGE_SEARCH_TYPE',
        searchType,
    };
}
