import gettext from 'airborne/gettext';
import {airHelpers} from "@getgoing/hurricane";

export const {
    isBaggageWeightExist,
    isFareBaggagePiecesIncluded,
    isFareBaggageWeightIncluded,
    isBaggageIncluded,
} = airHelpers;

export function extractBaggageAllowance(baggageAllowances, id = '1') {
    const baggage = baggageAllowances?.find(({travelerId}) => travelerId === id);

    return baggage?.baggageAllowance;
}

function getFareBaggageWeightText(fareBaggage) {
    if (!isFareBaggageWeightIncluded(fareBaggage)) {
        return '';
    }

    const {maxWeight, weightUnit} = fareBaggage;
    const weightUnitText = weightUnit === 'K' ? 'kg' : 'lb';

    return gettext('{max} max', {max: `${maxWeight}${weightUnitText}`});
}

function getFareBaggagePiecesText(fareBaggage) {
    if (!isFareBaggagePiecesIncluded(fareBaggage)) {
        return '';
    }

    const {maxPieces} = fareBaggage;
    return gettext('{count} pieces', {count: maxPieces});
}

export function getBaggageWeightText(fareBaggage) {
    const weightText = getFareBaggageWeightText(fareBaggage);
    const piecesText = getFareBaggagePiecesText(fareBaggage);

    return piecesText && weightText
        ? `${piecesText} (${weightText})`
        : piecesText || weightText;
}
