'process i18n';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import gettext  from 'airborne/gettext';
import settings from 'airborne/settings';
import identity from 'lodash/identity';
import {Link} from 'react-router-dom';
import {
    showBookingRecords,
    showBookingRecordsCars,
    showBookingRecordsAir,
    showExternalBookingRecordsAir
} from 'airborne/store/modules/header/actions/changeStep';
import {connect} from 'react-redux';
import Button from 'midoffice/components/Button';
import Glyphicons from 'midoffice/components/Glyphicons';
import {refreshBookings as searchHotels} from 'midoffice/bookings/actions';
import {refreshBookings as searchCars} from 'midoffice/car-bookings/actions';
import {refreshBookings as searchAir} from 'midoffice/air-bookings/actions';

@connect(null, {showBookingRecordsCars, showBookingRecords, showBookingRecordsAir, showExternalBookingRecordsAir})
class ProductTab extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        singular: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        isActive: PropTypes.bool.isRequired,
        wrapper: PropTypes.func,
        showBookingRecords: PropTypes.func.isRequired,
        showBookingRecordsCars: PropTypes.func.isRequired,
        showBookingRecordsAir: PropTypes.func.isRequired,
        showExternalBookingRecordsAir: PropTypes.func.isRequired,
    };

    static defaultProps = {
        wrapper: identity,
    };

    handleClick = () => {
        const {
            showExternalBookingRecordsAir,
            showBookingRecords,
            showBookingRecordsCars,
            showBookingRecordsAir,
            url,
        } = this.props;
        if (settings.SUB_PARTNER !== 'aft') {
            return null;
        }
        const showTab = {
            '/bookings/hotels/': showBookingRecords,
            '/bookings/cars/': showBookingRecordsCars,
            '/bookings/air/': showBookingRecordsAir,
            '/bookings/external/': showExternalBookingRecordsAir,
        }[url];
        return showTab();
    }

    render() {
        const {title, isActive, url, wrapper} = this.props;
        const className = classnames('tabs-inner__item', {
            'tabs-inner__item--active': isActive,
        });

        return (
            <Link to={url} className={className}>
                <span  className="tabs-inner__item__title">{wrapper(title)}</span>
            </Link>
        );
    }
}

@connect((({bookings}) => ({loading: bookings.loading})), {searchHotels, searchCars, searchAir})
class ProductTabs extends React.Component {
    static propTypes = {
        selected: PropTypes.string.isRequired,
        products: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            singular: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        })),
        searchHotels: PropTypes.func,
        searchCars: PropTypes.func,
        searchAir: PropTypes.func,
        withRefresh: PropTypes.bool,
        loading: PropTypes.bool,
    };

    handleSearch = () => {
        const {searchHotels, searchCars, searchAir, selected} = this.props;
        const searcher = {hotels: searchHotels, cars: searchCars, air: searchAir};
        searcher[selected]();
    };

    render() {
        const {selected, products, withRefresh, loading} = this.props;

        return (
            <div className="tabs-inner tabs-inner--categories">
                <nav className="tabs-inner__controls">
                    {products.map(product => (
                        <ProductTab
                            key={product.id}
                            isActive={selected === product.id}
                            {...product}
                        />
                    ))}
                </nav>
                {withRefresh && (
                    <Button
                        bsStyle="link"
                        disabled={loading}
                        className="btn-link--narrow btn-sm text-gray pull-right"
                        onClick={this.handleSearch}
                    >
                        <Glyphicons bsClass="glyphicon" glyph="refresh" />{' '}
                        {gettext('Refresh')}
                    </Button>
                )}
            </div>
        );
    }
}

export default ProductTabs;
