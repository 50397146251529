import { combineReducers } from 'redux';
import * as actionTypes from '../actionTypes';

const defaultData = [];
const data = (state = defaultData, { type, data }) => {
    switch (type) {
        case actionTypes.AIR_TICKETS_LOADING:
            return defaultData;
        case actionTypes.AIR_TICKETS_LOADED:
            return data;
        case actionTypes.AIR_TICKETS_FAIL:
            return defaultData;
        default:
            return state;
    }
};

const defaultLoading = false;
const loading = (state = defaultLoading, { type }) => {
    switch (type) {
        case actionTypes.AIR_TICKETS_LOADING:
            return true;
        case actionTypes.AIR_TICKETS_LOAD_END:
            return false;
        default:
            return state;
    }
};

const defaultErrors = null;
const errors = (state = defaultErrors, { type, error }) => {
    switch (type) {
        case actionTypes.AIR_TICKETS_FAIL:
            return error;
        case actionTypes.AIR_TICKETS_LOADING:
        case actionTypes.AIR_TICKETS_LOADED:
            return defaultErrors;
        default:
            return state;
    }
};

const tickets = combineReducers({
    data,
    loading,
    errors,
});

export default tickets;
