/* global global */
import find from 'lodash/find';
import i18next from 'i18next';

import settings from 'airborne/settings';
import {syncCompanySettings} from 'airborne/store/modules/homepage/actions/pnr';
import {reloadCarSearch} from 'airborne/store/modules/search_cars/actions/search';
import {reloadAirSearch} from 'airborne/air/store/fare_search/actions/searchAir';
import {reloadHotelsSearch} from 'airborne/store/modules/search_hotels/actions/search';
import systemData from 'airborne/systemData';
import api from 'midoffice/helpers/api';
import {clearOffers} from 'airborne/store/modules/search_hotels/actions/offers';

export function i18nLoading() {
    return {type: 'LANG_LOADING'};
}
export function i18nLoaded() {
    return {type: 'LANG_LOADED'};
}

export function loadI18N() {
    const {
        'LANGUAGE_CODE': lang,
        'CURRENCY_CODE': currency,
    } = settings;

    return {type: 'LOAD_I18N', lang, currency};
}


function gettextUrl(language) {
    const translationUrl = systemData?.common?.AIRBORNE_FE_TRANSLATION_URL;
    if (!translationUrl) {
        return '';
    }
    return `${translationUrl}${language}.json`;
}

function getLocale([code, title, locale]) { // eslint-disable-line no-unused-vars
    return locale;
}

function localeForLang(lang) {
    return getLocale(find(settings.LANGUAGES, ([code])=> code === lang));
}

function fetchGettext(lang, isChangeGlobalLn) {
    const url = gettextUrl(lang);

    if (!url) {
        return Promise.resolve().then(()=> {
            i18next.changeLanguage(lang);
            isChangeGlobalLn && settings.set({
                USER: {...settings.USER, 'lang_code': lang},
            });
            settings.set({
                LANGUAGE_CODE: lang,
                USER_LOCALE: localeForLang(lang),
            });
        });
    }

    return api('GET', url)
        .then((catalog)=> {
            global.translations = catalog; // eslint-disable-line immutable/no-mutation
            i18next.addResourceBundle(lang, 'translation', catalog, true, true);
            i18next.changeLanguage(lang);
            settings.set({
                LANGUAGE_CODE: lang,
                USER_LOCALE: localeForLang(lang),
            });
            isChangeGlobalLn && settings.set({
                USER: {...settings.USER, 'lang_code': lang},
            });
        });
}

export function setLang(lang, isChangeGlobalLn=false) {
    return function (dispatch) {
        const isAutorized = !(settings.USER && settings.USER.id);
        const data = {
            'session_only': true,
            'lang_code': lang,
        };
        if (isAutorized) dispatch(i18nLoading());
        return Promise.all([
            api('GET', '/i18n/change-language', {data}),
            fetchGettext(lang, isChangeGlobalLn),
        ])
            .then(()=> {
                const [,,langLocale] = settings.LANGUAGES.find(([langCode]) => langCode === lang);
                settings.set({USER_LOCALE: langLocale});
                if (isAutorized)  dispatch(i18nLoaded());
                dispatch({type: 'SET_LANG', lang, index: 'all'});
                dispatch(clearOffers());
                return dispatch(syncCompanySettings());
            },
            (response)=> {
                dispatch({type: 'NET_FAIL', response});
            });
    };
}

export const CHANGE_CURRENCY_REQUEST_URL = '/i18n/change-currency';

export function setCurrency(currency) {
    return function setCurrencyD(dispatch) {
        const data = {
            'session_only': true,
            'currency_code': currency,
        };

        dispatch(clearOffers()); // GG-34751

        return api('GET', CHANGE_CURRENCY_REQUEST_URL, {data})
            .then(()=> {
                dispatch({type: 'SET_CURRENCY', currency, index: 'all'});
                return currency;
            })
            .then((currency) => {
                const isCompanyCurrency = currency === '...';
                if (isCompanyCurrency) {
                    dispatch(reloadHotelsSearch());
                }
                dispatch(reloadCarSearch());
                dispatch(reloadAirSearch());
            })
            .catch(
                ()=> {
                    dispatch({type: 'NET_FAIL'});
                });
    };
}
