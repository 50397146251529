import gettext from 'airborne/gettext';

const getCountries = () => [
    ['AF', gettext('Afghanistan')],
    ['AL', gettext('Albania')],
    ['DZ', gettext('Algeria')],
    ['AS', gettext('American Samoa')],
    ['AD', gettext('Andorra')],
    ['AO', gettext('Angola')],
    ['AI', gettext('Anguilla')],
    ['AG', gettext('Antigua and Barbuda')],
    ['AR', gettext('Argentina')],
    ['AM', gettext('Armenia')],
    ['AW', gettext('Aruba')],
    ['AU', gettext('Australia')],
    ['AT', gettext('Austria')],
    ['AZ', gettext('Azerbaijan')],
    ['BS', gettext('Bahamas')],
    ['BH', gettext('Bahrain')],
    ['BD', gettext('Bangladesh')],
    ['BB', gettext('Barbados')],
    ['BY', gettext('Belarus')],
    ['BE', gettext('Belgium')],
    ['BZ', gettext('Belize')],
    ['BJ', gettext('Benin')],
    ['BM', gettext('Bermuda')],
    ['BT', gettext('Bhutan')],
    ['BO', gettext('Bolivia')],
    ['BQ', gettext('Bonaire, Sint Eustatius and Saba')],
    ['BA', gettext('Bosnia and Herzegovina')],
    ['BW', gettext('Botswana')],
    ['BV', gettext('Bouvet Island')],
    ['BR', gettext('Brazil')],
    ['IO', gettext('British Indian Ocean Territory')],
    ['VG', gettext('British Virgin Islands')],
    ['BN', gettext('Brunei')],
    ['BG', gettext('Bulgaria')],
    ['BF', gettext('Burkina Faso')],
    ['BI', gettext('Burundi')],
    ['KH', gettext('Cambodia')],
    ['CM', gettext('Cameroon')],
    ['CA', gettext('Canada')],
    ['CV', gettext('Cape Verde')],
    ['KY', gettext('Cayman Islands')],
    ['CF', gettext('Central African Republic')],
    ['TD', gettext('Chad')],
    ['CL', gettext('Chile')],
    ['CN', gettext('China')],
    ['CX', gettext('Christmas Island')],
    ['CC', gettext('Cocos Islands')],
    ['CO', gettext('Colombia')],
    ['KM', gettext('Comoros')],
    ['CK', gettext('Cook Islands')],
    ['CR', gettext('Costa Rica')],
    ['CI', gettext("Cote d'Ivoire")],
    ['HR', gettext('Croatia')],
    ['CW', gettext('Curacao')],
    ['CY', gettext('Cyprus')],
    ['CZ', gettext('Czech Republic')],
    ['CD', gettext('Democratic Republic of the Congo')],
    ['DK', gettext('Denmark')],
    ['DJ', gettext('Djibouti')],
    ['DM', gettext('Dominica')],
    ['DO', gettext('Dominican Republic')],
    ['EC', gettext('Ecuador')],
    ['EG', gettext('Egypt')],
    ['SV', gettext('El Salvador')],
    ['GQ', gettext('Equatorial Guinea')],
    ['ER', gettext('Eritrea')],
    ['EE', gettext('Estonia')],
    ['SZ', gettext('Eswatini')],
    ['ET', gettext('Ethiopia')],
    ['FK', gettext('Falkland Islands')],
    ['FO', gettext('Faroe Islands')],
    ['FM', gettext('Federated States of Micronesia')],
    ['FJ', gettext('Fiji')],
    ['FI', gettext('Finland')],
    ['FR', gettext('France')],
    ['GF', gettext('French Guiana')],
    ['PF', gettext('French Polynesia')],
    ['TF', gettext('French Southern and Antarctic Territories')],
    ['GA', gettext('Gabon')],
    ['GM', gettext('Gambia')],
    ['GE', gettext('Georgia')],
    ['DE', gettext('Germany')],
    ['GH', gettext('Ghana')],
    ['GI', gettext('Gibraltar')],
    ['GR', gettext('Greece')],
    ['GL', gettext('Greenland')],
    ['GD', gettext('Grenada')],
    ['GP', gettext('Guadeloupe')],
    ['GU', gettext('Guam')],
    ['GT', gettext('Guatemala')],
    ['GG', gettext('Guernsey')],
    ['GN', gettext('Guinea')],
    ['GW', gettext('Guinea-Bissau')],
    ['GY', gettext('Guyana')],
    ['HT', gettext('Haiti')],
    ['HM', gettext('Heard and McDonald Islands')],
    ['HN', gettext('Honduras')],
    ['HK', gettext('Hong Kong SAR')],
    ['HU', gettext('Hungary')],
    ['IS', gettext('Iceland')],
    ['IN', gettext('India')],
    ['ID', gettext('Indonesia')],
    ['IR', gettext('Iran')],
    ['IQ', gettext('Iraq')],
    ['IE', gettext('Ireland')],
    ['IL', gettext('Israel')],
    ['IT', gettext('Italy')],
    ['JM', gettext('Jamaica')],
    ['JP', gettext('Japan')],
    ['JE', gettext('Jersey')],
    ['JO', gettext('Jordan')],
    ['KZ', gettext('Kazakhstan')],
    ['KE', gettext('Kenya')],
    ['KI', gettext('Kiribati')],
    ['KW', gettext('Kuwait')],
    ['KG', gettext('Kyrgyzstan')],
    ['LA', gettext('Laos')],
    ['LV', gettext('Latvia')],
    ['LB', gettext('Lebanon')],
    ['LS', gettext('Lesotho')],
    ['LR', gettext('Liberia')],
    ['LY', gettext('Libya')],
    ['LI', gettext('Liechtenstein')],
    ['LT', gettext('Lithuania')],
    ['LU', gettext('Luxembourg')],
    ['MO', gettext('Macau SAR')],
    ['MG', gettext('Madagascar')],
    ['MW', gettext('Malawi')],
    ['MY', gettext('Malaysia')],
    ['MV', gettext('Maldives')],
    ['ML', gettext('Mali')],
    ['MT', gettext('Malta')],
    ['MH', gettext('Marshall Islands')],
    ['MQ', gettext('Martinique')],
    ['MR', gettext('Mauritania')],
    ['MU', gettext('Mauritius')],
    ['YT', gettext('Mayotte')],
    ['MX', gettext('Mexico')],
    ['MD', gettext('Moldova')],
    ['MC', gettext('Monaco')],
    ['MN', gettext('Mongolia')],
    ['ME', gettext('Montenegro')],
    ['MS', gettext('Montserrat')],
    ['MA', gettext('Morocco')],
    ['MZ', gettext('Mozambique')],
    ['MM', gettext('Myanmar')],
    ['NA', gettext('Namibia')],
    ['NR', gettext('Nauru')],
    ['NP', gettext('Nepal')],
    ['NL', gettext('Netherlands')],
    ['NC', gettext('New Caledonia')],
    ['NZ', gettext('New Zealand')],
    ['NI', gettext('Nicaragua')],
    ['NE', gettext('Niger')],
    ['NG', gettext('Nigeria')],
    ['NU', gettext('Niue')],
    ['NF', gettext('Norfolk Island')],
    ['MK', gettext('North Macedonia')],
    ['MP', gettext('Northern Mariana Islands')],
    ['NO', gettext('Norway')],
    ['OM', gettext('Oman')],
    ['PK', gettext('Pakistan')],
    ['PW', gettext('Palau')],
    ['PS', gettext('Palestinian Territories')],
    ['PA', gettext('Panama')],
    ['PG', gettext('Papua New Guinea')],
    ['PY', gettext('Paraguay')],
    ['PE', gettext('Peru')],
    ['PH', gettext('Philippines')],
    ['PN', gettext('Pitcairn Island')],
    ['PL', gettext('Poland')],
    ['PT', gettext('Portugal')],
    ['PR', gettext('Puerto Rico')],
    ['QA', gettext('Qatar')],
    ['CG', gettext('Republic of the Congo')],
    ['RE', gettext('Reunion')],
    ['RO', gettext('Romania')],
    ['RU', gettext('Russia')],
    ['RW', gettext('Rwanda')],
    ['WS', gettext('Samoa')],
    ['SM', gettext('San Marino')],
    ['ST', gettext('Sao Tome and Principe')],
    ['SA', gettext('Saudi Arabia')],
    ['SN', gettext('Senegal')],
    ['RS', gettext('Serbia')],
    ['SC', gettext('Seychelles')],
    ['SL', gettext('Sierra Leone')],
    ['SG', gettext('Singapore')],
    ['SX', gettext('Sint Maarten')],
    ['SK', gettext('Slovakia')],
    ['SI', gettext('Slovenia')],
    ['SB', gettext('Solomon Islands')],
    ['SO', gettext('Somalia')],
    ['ZA', gettext('South Africa')],
    ['KR', gettext('South Korea')],
    ['SS', gettext('South Sudan')],
    ['ES', gettext('Spain')],
    ['LK', gettext('Sri Lanka')],
    ['BL', gettext('St. Barthelemy')],
    ['SH', gettext('St. Helena')],
    ['KN', gettext('St. Kitts and Nevis')],
    ['LC', gettext('St. Lucia')],
    ['MF', gettext('St. Martin')],
    ['PM', gettext('St. Pierre and Miquelon')],
    ['VC', gettext('St. Vincent and the Grenadines')],
    ['SD', gettext('Sudan')],
    ['SR', gettext('Suriname')],
    ['SJ', gettext('Svalbard')],
    ['SE', gettext('Sweden')],
    ['CH', gettext('Switzerland')],
    ['SY', gettext('Syria')],
    ['TW', gettext('Taiwan')],
    ['TJ', gettext('Tajikistan')],
    ['TZ', gettext('Tanzania')],
    ['TH', gettext('Thailand')],
    ['TL', gettext('Timor Leste')],
    ['TG', gettext('Togo')],
    ['TK', gettext('Tokelau')],
    ['TO', gettext('Tonga')],
    ['TT', gettext('Trinidad and Tobago')],
    ['TN', gettext('Tunisia')],
    ['TR', gettext('Turkey')],
    ['TM', gettext('Turkmenistan')],
    ['TC', gettext('Turks and Caicos')],
    ['TV', gettext('Tuvalu')],
    ['VI', gettext('U.S. Virgin Islands')],
    ['UG', gettext('Uganda')],
    ['UA', gettext('Ukraine')],
    ['AE', gettext('United Arab Emirates')],
    ['GB', gettext('United Kingdom')],
    ['US', gettext('United States of America')],
    ['UY', gettext('Uruguay')],
    ['UM', gettext('US Minor Outlying Islands')],
    ['UZ', gettext('Uzbekistan')],
    ['VU', gettext('Vanuatu')],
    ['VA', gettext('Vatican City')],
    ['VE', gettext('Venezuela')],
    ['VN', gettext('Vietnam')],
    ['WF', gettext('Wallis and Futuna')],
    ['YE', gettext('Yemen')],
    ['ZM', gettext('Zambia')],
    ['ZW', gettext('Zimbabwe')],
];

export default getCountries;
