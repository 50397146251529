import { combineReducers } from 'redux';
import * as actionTypes from '../actionTypes';

const defaultData = {};
const data = (state = defaultData, { type, data }) => {
    switch (type) {
        case actionTypes.AIR_TICKETS_REFUND_LOADING:
            return defaultData;
        case actionTypes.AIR_TICKETS_REFUND_LOADED:
        case actionTypes.AIR_TICKETS_REFUND_FAIL:
            return {
                ...state,
                ...data,
            };
        default:
            return state;
    }
};

const defaultLoading = false;
const loading = (state = defaultLoading, { type }) => {
    switch (type) {
        case actionTypes.AIR_TICKETS_REFUND_LOADING:
            return true;
        case actionTypes.AIR_TICKETS_REFUND_LOAD_END:
            return false;
        default:
            return state;
    }
};

const defaultErrors = null;
const errors = (state = defaultErrors, { type, error }) => {
    switch (type) {
        case actionTypes.AIR_TICKETS_REFUND_FAIL:
            return error;
        case actionTypes.AIR_TICKETS_REFUND_LOADING:
        case actionTypes.AIR_TICKETS_REFUND_LOADED:
            return defaultErrors;
        default:
            return state;
    }
};

const ticketVoiding = combineReducers({
    data,
    loading,
    errors,
});

export default ticketVoiding;
