import {batch} from 'react-redux';
import pick from 'lodash/pick';
import {
    getAirExchangeBookingOriginDestinationCodes,
    getAirExchangeBookingDates,
    getAirExchangeFormValue, getAirExchangeFlownIndicators, getAirExchangeProvider,
} from 'airborne/air/store/exchange/selectors';
import {changeSingleAirExchangeFormValue} from './form';
import {getTripType} from 'airborne/air/homepage/helpers/common';
import {AIR_TRIP_TYPES} from 'airborne/air/homepage/types';
import {getDateLimits} from 'airborne/air/homepage/helpers/dateLimits';
import AirDestinationAutocomplete from "airborne/air/homepage/AirDestinationAutocomplete";
import {INITIAL_ORIGIN_DESTINATION_VALUE} from "airborne/store/modules/homepage/reducers/destination";

function getLookupTripType(destinationCodes) {
    const commonDestinations = destinationCodes.map(([departureCode, arrivalCode]) => ({
        pickUp: {iataCode: departureCode},
        dropOff: {iataCode: arrivalCode},
    }));

    return getTripType(commonDestinations);
}

const getDestinations = async (originDestinationCodes) => {
    return await Promise.all(
        originDestinationCodes.map(async ([departureCode, arrivalCode], index) => {
            const departureAutocomplete = new AirDestinationAutocomplete(`exchange_departure_${index}`, false);
            const arrivalAutocomplete = new AirDestinationAutocomplete(`exchange_arrival_${index}`, false);

            const results = await Promise.all([
                departureAutocomplete.query(departureCode),
                arrivalAutocomplete.query(arrivalCode),
            ]);

            return results.map(citiesAndAirports => {
                return citiesAndAirports.find(element => element.type === 'a');
            });
        })
    );
};

export const prefillAirExchangeForm = () => async (dispatch, getState) => {
    const originDestinationCodes = getAirExchangeBookingOriginDestinationCodes(getState());
    const dates = getAirExchangeBookingDates(getState());
    const provider = getAirExchangeProvider(getState());
    const isSabre = provider === 'sabre';

    const destinations = await getDestinations(originDestinationCodes);
    const [dateMin, dateMax = null] = dates;

    const originalTripType = getLookupTripType(originDestinationCodes);
    const tripType = isSabre ? AIR_TRIP_TYPES.MULTI_CITY : originalTripType;
    const forceSabreMultiCity = isSabre && originalTripType === AIR_TRIP_TYPES.ROUND_TRIP;

    const {originDestinations} = getAirExchangeFormValue(getState());
    const flownIndicators = getAirExchangeFlownIndicators(getState());

    const filledDestinations = destinations.map(([pickUp, dropOff], index) => {
        const isFlownDest = flownIndicators[index];

        const dateRange = forceSabreMultiCity
            ? {min: dates[index]} // Since we force multi city search for Sabre, we can pick date by dest index
            : {min: dateMin, max: dateMax};

        return {
            ...originDestinations?.[index],
            pickUp,
            dropOff,
            dateRange,
            flownIndicator: isFlownDest,
            ...(isSabre && isFlownDest ? {preserveDest: 'FlightsAndFares'} : {}),
            // since we transform all sabre trips to multi-city, some default values
            // should be pre-populated for destinations added with that transformation
            // (against adding them with the form defaultValue props)
            ...(forceSabreMultiCity
                ? pick(INITIAL_ORIGIN_DESTINATION_VALUE, ['noCabinPreference', 'departureCabin', 'arrivalCabin'])
                : {}
            )
        }
    });

    const isRoundTrip = tripType === AIR_TRIP_TYPES.ROUND_TRIP;
    const firstODFlown = filledDestinations[0].flownIndicator;
    const roundTripOriginDestinations = firstODFlown ? filledDestinations : filledDestinations.slice(0, 1);

    if (isRoundTrip && firstODFlown) {
        const [origin, destination] = roundTripOriginDestinations;
        const {dateRange: {min, max}} = origin;
        const {min: today} = getDateLimits();
        origin.departureDate = min;
        origin.dateRange.min = today;
        destination.dateRange.min = max;
    }

    const formOriginDestinations = isRoundTrip
        ? roundTripOriginDestinations
        : filledDestinations;

    batch(() => {
        dispatch(changeSingleAirExchangeFormValue(tripType, 'tripType'));
        dispatch(changeSingleAirExchangeFormValue(formOriginDestinations, 'originDestinations'));
    });

};
