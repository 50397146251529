import {dropEmpty} from 'midoffice/helpers/urlParams';

import {parse} from 'airborne/types';

import air from 'airborne/store/modules/search_hotels/actions/air';
import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {serializeTSPM} from 'airborne/homepage2/helpers/search';
import {getSelectedRateKey} from 'airborne/cars/checkout/selectors';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';
import {loadCarAvailParams} from 'airborne/store/modules/search_cars/actions/searchRequest';

export const getNegotiatedNumberCode = (numbers) => {
    if (!Array.isArray(numbers) || !numbers.length) {
        return null;
    }

    return numbers[0].split(':')[1];
};

export function loadCheckoutData() {
    return function loadCheckoutDataD(dispatch, getState) {
        const rateKey = getSelectedRateKey(getState());
        const profile = getPnrProfile(getState(), 0);

        dispatch({type: 'CARS_CHECKOUT_DATA_LOADING'});

        return air('GET', '/cars/get_checkout_data/', dropEmpty({
            'configuration_id': getSelectedConfiguration(getState()) || profile.company,
            'rate_key': rateKey,
            ...serializeTSPM(profile),
        })).then((data)=> {
            dispatch({
                type: 'CARS_CHECKOUT_DATA_LOADED',
                checkoutData: parse('carCheckoutData', data),
            });
        }).catch(()=> {
            dispatch({
                type: 'CARS_CHECKOUT_DATA_FAIL',
            });
        });
    };
}

export function loadDirectSellCheckoutData() {
    return function loadDirectSellCheckoutDataD(dispatch, getState) {
        const profile = getPnrProfile(getState(), 0);
        const params = loadCarAvailParams(getState(), null, true);

        dispatch({type: 'CARS_CHECKOUT_DATA_LOADING'});

        return air('GET', '/cars/get_direct_sell_checkout_data', dropEmpty({
            'configuration_id': getSelectedConfiguration(getState()) || profile.company,
            'cd_number': getNegotiatedNumberCode(params['cd_numbers']),
            'vendor_code': params.carVendor,
            ...serializeTSPM(profile),
        })).then((data)=> {
            dispatch({type: 'DIRECT_SELL_LOADING_FINISHED'});
            dispatch({
                type: 'CARS_CHECKOUT_DATA_LOADED',
                checkoutData: parse('carCheckoutData', data),
            });
        }).catch((response)=> {
            const message = response?.body?.errors[0]?.message;
            dispatch({type: 'DIRECT_SELL_LOADING_FINISHED'});
            dispatch({type: 'DIRECT_SELL_SEARCH_REQUEST_ERROR', error: message});
            dispatch({
                type: 'CARS_CHECKOUT_DATA_FAIL',
            });
        });
    };
}
