import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import {getDisplayNames, addDisplayNames} from './helpers';


function getDefaultSsoConfiguration() {
    return [{
        'connection_type': null,
        'enabled': false,
        'redirect_url': null,
        'service_provider': null,
        'display_names': getDisplayNames(),
    }];
}

export function parse(data) {
    const {'sso_configurations': ssoConfigurations} = data;
    return {
        ...data,
        'sso_configurations': isEmpty(ssoConfigurations) ? getDefaultSsoConfiguration() : addDisplayNames(ssoConfigurations),
    };
}

export function serialize(data) {
    return {
        ...omit(data, ['regional:language']),
    };
};
