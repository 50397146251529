import gettext from 'airborne/gettext';

const getCurrencies = () => [
    ['...', gettext('Company Currency')],
    ['AED', gettext('United Arab Emirates Dirham')],
    ['AFN', gettext('Afghan Afghani')],
    ['ALL', gettext('Albanian Lek')],
    ['AMD', gettext('Armenian Dram')],
    ['ANG', gettext('Netherlands Antillean Guilder')],
    ['AOA', gettext('Angolan Kwanza')],
    ['ARS', gettext('Argentine Peso')],
    ['AUD', gettext('Australian Dollar')],
    ['AWG', gettext('Aruban Florin')],
    ['AZN', gettext('Azerbaijani Manat')],
    ['BAM', gettext('Bosnia-Herzegovina Convertible Mark')],
    ['BBD', gettext('Barbadian Dollar')],
    ['BDT', gettext('Bangladeshi Taka')],
    ['BGN', gettext('Bulgarian Lev')],
    ['BHD', gettext('Bahraini Dinar')],
    ['BIF', gettext('Burundian Franc')],
    ['BMD', gettext('Bermudan Dollar')],
    ['BND', gettext('Brunei Dollar')],
    ['BOB', gettext('Bolivian Boliviano')],
    ['BRL', gettext('Brazilian Real')],
    ['BSD', gettext('Bahamian Dollar')],
    ['BTN', gettext('Bhutanese Ngultrum')],
    ['BWP', gettext('Botswanan Pula')],
    ['BYN', gettext('Belarusian Ruble')],
    ['BZD', gettext('Belize Dollar')],
    ['CAD', gettext('Canadian Dollar')],
    ['CDF', gettext('Congolese Franc')],
    ['CHF', gettext('Swiss Franc')],
    ['CLP', gettext('Chilean Peso')],
    ['CNH', gettext('Chinese Yuan (Offshore)')],
    ['CNY', gettext('Chinese Yuan')],
    ['COP', gettext('Colombian Peso')],
    ['CRC', gettext('Costa Rican Colón')],
    ['CUC', gettext('Cuban Convertible Peso')],
    ['CUP', gettext('Cuban Peso')],
    ['CVE', gettext('Cape Verdean Escudo')],
    ['CZK', gettext('Czech Republic Koruna')],
    ['DJF', gettext('Djiboutian Franc')],
    ['DKK', gettext('Danish Krone')],
    ['DOP', gettext('Dominican Peso')],
    ['DZD', gettext('Algerian Dinar')],
    ['EGP', gettext('Egyptian Pound')],
    ['ERN', gettext('Eritrean Nakfa')],
    ['ETB', gettext('Ethiopian Birr')],
    ['EUR', gettext('Euro')],
    ['FJD', gettext('Fijian Dollar')],
    ['FKP', gettext('Falkland Islands Pound')],
    ['GBP', gettext('British Pound Sterling')],
    ['GEL', gettext('Georgian Lari')],
    ['GGP', gettext('Guernsey Pound')],
    ['GHS', gettext('Ghanaian Cedi')],
    ['GIP', gettext('Gibraltar Pound')],
    ['GMD', gettext('Gambian Dalasi')],
    ['GNF', gettext('Guinean Franc')],
    ['GTQ', gettext('Guatemalan Quetzal')],
    ['GYD', gettext('Guyanaese Dollar')],
    ['HKD', gettext('Hong Kong Dollar')],
    ['HNL', gettext('Honduran Lempira')],
    ['HRK', gettext('Croatian Kuna')],
    ['HTG', gettext('Haitian Gourde')],
    ['HUF', gettext('Hungarian Forint')],
    ['IDR', gettext('Indonesian Rupiah')],
    ['ILS', gettext('Israeli New Sheqel')],
    ['IMP', gettext('Manx pound')],
    ['INR', gettext('Indian Rupee')],
    ['IQD', gettext('Iraqi Dinar')],
    ['IRR', gettext('Iranian Rial')],
    ['ISK', gettext('Icelandic Króna')],
    ['JEP', gettext('Jersey Pound')],
    ['JMD', gettext('Jamaican Dollar')],
    ['JOD', gettext('Jordanian Dinar')],
    ['JPY', gettext('Japanese Yen')],
    ['KES', gettext('Kenyan Shilling')],
    ['KGS', gettext('Kyrgystani Som')],
    ['KHR', gettext('Cambodian Riel')],
    ['KMF', gettext('Comorian Franc')],
    ['KPW', gettext('North Korean Won')],
    ['KRW', gettext('South Korean Won')],
    ['KWD', gettext('Kuwaiti Dinar')],
    ['KYD', gettext('Cayman Islands Dollar')],
    ['KZT', gettext('Kazakhstani Tenge')],
    ['LAK', gettext('Laotian Kip')],
    ['LBP', gettext('Lebanese Pound')],
    ['LKR', gettext('Sri Lankan Rupee')],
    ['LRD', gettext('Liberian Dollar')],
    ['LSL', gettext('Lesotho Loti')],
    ['LYD', gettext('Libyan Dinar')],
    ['MAD', gettext('Moroccan Dirham')],
    ['MDL', gettext('Moldovan Leu')],
    ['MGA', gettext('Malagasy Ariary')],
    ['MKD', gettext('Macedonian Denar')],
    ['MMK', gettext('Myanma Kyat')],
    ['MNT', gettext('Mongolian Tugrik')],
    ['MOP', gettext('Macanese Pataca')],
    ['MRU', gettext('Mauritanian Ouguiya')],
    ['MUR', gettext('Mauritian Rupee')],
    ['MVR', gettext('Maldivian Rufiyaa')],
    ['MWK', gettext('Malawian Kwacha')],
    ['MXN', gettext('Mexican Peso')],
    ['MYR', gettext('Malaysian Ringgit')],
    ['MZN', gettext('Mozambican Metical')],
    ['NAD', gettext('Namibian Dollar')],
    ['NGN', gettext('Nigerian Naira')],
    ['NIO', gettext('Nicaraguan Córdoba')],
    ['NOK', gettext('Norwegian Krone')],
    ['NPR', gettext('Nepalese Rupee')],
    ['NZD', gettext('New Zealand Dollar')],
    ['OMR', gettext('Omani Rial')],
    ['PAB', gettext('Panamanian Balboa')],
    ['PEN', gettext('Peruvian Nuevo Sol')],
    ['PGK', gettext('Papua New Guinean Kina')],
    ['PHP', gettext('Philippine Piso')],
    ['PKR', gettext('Pakistani Rupee')],
    ['PLN', gettext('Polish Zloty')],
    ['PYG', gettext('Paraguayan Guarani')],
    ['QAR', gettext('Qatari Rial')],
    ['RON', gettext('Romanian Leu')],
    ['RSD', gettext('Serbian Dinar')],
    ['RUB', gettext('Russian Ruble')],
    ['RWF', gettext('Rwandan Franc')],
    ['SAR', gettext('Saudi Riyal')],
    ['SBD', gettext('Solomon Islands Dollar')],
    ['SCR', gettext('Seychellois Rupee')],
    ['SDG', gettext('Sudanese Pound')],
    ['SEK', gettext('Swedish Krona')],
    ['SGD', gettext('Singapore Dollar')],
    ['SHP', gettext('Saint Helena Pound')],
    ['SLL', gettext('Sierra Leonean Leone')],
    ['SOS', gettext('Somali Shilling')],
    ['SRD', gettext('Surinamese Dollar')],
    ['SSP', gettext('South Sudanese Pound')],
    ['STN', gettext('Dobra')],
    ['SVC', gettext('Salvadoran Colón')],
    ['SYP', gettext('Syrian Pound')],
    ['SZL', gettext('Swazi Lilangeni')],
    ['THB', gettext('Thai Baht')],
    ['TJS', gettext('Tajikistani Somoni')],
    ['TMT', gettext('Turkmenistani Manat')],
    ['TND', gettext('Tunisian Dinar')],
    ['TOP', gettext('Tongan Paʻanga')],
    ['TRY', gettext('Turkish Lira')],
    ['TTD', gettext('Trinidad and Tobago Dollar')],
    ['TWD', gettext('New Taiwan Dollar')],
    ['TZS', gettext('Tanzanian Shilling')],
    ['UAH', gettext('Ukrainian Hryvnia')],
    ['UGX', gettext('Ugandan Shilling')],
    ['USD', gettext('United States Dollar')],
    ['UYU', gettext('Uruguayan Peso')],
    ['UZS', gettext('Uzbekistan Som')],
    ['VEF', gettext('Venezuelan Bolívar Fuerte')],
    ['VES', gettext('Venezuelan Bolívar Soberano')],
    ['VND', gettext('Vietnamese Dong')],
    ['VUV', gettext('Vanuatu Vatu')],
    ['WST', gettext('Samoan Tala')],
    ['XAF', gettext('CFA Franc BEAC')],
    ['XCD', gettext('East Caribbean Dollar')],
    ['XDR', gettext('Special Drawing Rights')],
    ['XOF', gettext('CFA Franc BCEAO')],
    ['XPF', gettext('CFP Franc')],
    ['YER', gettext('Yemeni Rial')],
    ['ZAR', gettext('South African Rand')],
    ['ZMW', gettext('Zambian Kwacha')],
    ['ZWL', gettext('Zimbabwean Dollar')],
    ['___', gettext('Local Currency')],
];

export default getCurrencies;
