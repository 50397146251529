import {applyTexts} from 'midoffice/helpers/phrases';

import {
    ADDED,
    BULK_ADDED,
    BULK_FAILED,
    FILECHECK_FAILED,
    BULK_LOADING,
    MODAL_SHOW,
    MODAL_UPDATE,
    MODAL_HIDE,
    NET_FAIL,
} from '../actions/types';

const ERROR = 'We\'re sorry, our system has encountered ' +
              'an error. Please try again in a few minutes.';


function initialState() {
    return {kind: null};
}


function showModal(kind, data) {
    return {data, kind};
}


function updateModal(state, data) {
    return {...state, data};
}


function hideModal() {
    return initialState();
}


function bulkProgressModal(state) {
    return {data: state.data, kind: 'progress'};
}


function bulkUploadSuccess(action, title, message) {
    const {fileName, response} = action;
    return showModal('success', {
        count: response.created_count,
        fileName,
        title,
        message,
    });
}

function fileCheckFailed(action, data) {
    return showModal('error', {
        title: data.title,
        message: data.message,
    });
}

function bulkUploadFailed(action, title) {
    const {response, fileName} = action;

    if (response.request.isAborted) {
        return showModal('cancel', {fileName, title});
    }

    if (response.request.isTimedOut) {
        return showModal('error', {
            fileName,
            message: 'Our system encountered a timeout error. Please, try again later.',
            title,
        });
    }

    const {body} = response;
    const message = (body && body.message) || ERROR;

    return showModal('error', {
        fileName,
        message,
        url: body && body.error_file_url,
        title,
    });
}


function companiesUploadSuccess(action) {
    const title = 'Bulk Upload Companies';
    const count = action.response.created_count;
    return bulkUploadSuccess(
        action,
        title,
        applyTexts(
            '{count} company added successfully.',
            '{count} companies added successfully.',
            {count},
        ),
    );
}


function companiesUploadFailed(action) {
    const title = 'Bulk Upload Companies';
    return bulkUploadFailed(action, title);
}


function reportAdded(state, action) {
    const {data: {
        'report_url': reportUrl,
        'send_to': sendTo,
        'auto_reporting': autoReporting
    }} = action;

    return showModal('reportProcessing', {
        type: 'success',
        autoReporting,
        reportUrl,
        sendTo
    });
}


function reportNetFailed(state, action) {
    const errors = action.response.body;
    return showModal('reportProcessing', {
        errors,
        message: ERROR,
        type: 'error',
    });
}


function agentsUploadSuccess(action) {
    const count = action.response.created_count;
    return bulkUploadSuccess(
        action,
        'Upload Agents',
        applyTexts(
            '{count} agent added successfully.',
            '{count} agents added successfully.',
            {count},
        ),
    );
}

function usersUploadSuccess(action) {
    const count = action.response.created_count;
    return bulkUploadSuccess(
        action,
        'Upload Users',
        applyTexts(
            '{count} user added successfully.',
            '{count} users added successfully.',
            {count},
        ),
    );
}


function agentsUploadFailed(action) {
    return bulkUploadFailed(action, 'Upload Agents');
}

function usersUploadFailed(action) {
    return bulkUploadFailed(action, 'Upload Users');
}

function locationsUploadSuccess(action) {
    const count = action.response.created_count;
    return bulkUploadSuccess(
        action,
        'Upload Locations',
        applyTexts(
            '{count} location added successfully.',
            '{count} locations added successfully.',
            {count},
        ),
    );
}

function locationsUploadFailed(action) {
    return bulkUploadFailed(action, 'Upload Locations');
}

function rateCapsUploadSuccess(action) {
    return bulkUploadSuccess(
        action,
        'Upload Rate Caps',
        'Rate Caps uploaded successfully.'
    );
}

function rateCapsUploadFailed(action) {
    return bulkUploadFailed(action, 'Upload Rate Caps');
}

function hotelsUploadSuccess(action) {
    const count = action.response.created_count;
    return bulkUploadSuccess(
        action,
        'Upload Hotels',
        applyTexts(
            '{count} hotel added successfully.',
            '{count} hotels added successfully.',
            {count},
        ),
    );
}


function hotelsUploadFailed(action) {
    return bulkUploadFailed(action, 'Upload Hotels');
}

export default function modal(state, action) {
    if (!state) {
        return initialState();
    }

    if (action.type === MODAL_SHOW) {
        return showModal(action.kind, action.data);
    }

    if (action.type === MODAL_UPDATE) {
        return updateModal(state, action.data);
    }

    if (action.type === MODAL_HIDE) {
        return hideModal();
    }

    if (action.type === BULK_LOADING) {
        return bulkProgressModal(state);
    }

    if (action.type === FILECHECK_FAILED) {
        return fileCheckFailed(action, action.data);
    }

    if (action.endpoint === 'reports') {
        if (action.type === ADDED) {
            return reportAdded(state, action);
        }
        if (action.type === NET_FAIL) {
            return reportNetFailed(state, action);
        }
    }

    if (action.endpoint === 'agents') {
        if (action.type === BULK_ADDED) {
            return agentsUploadSuccess(action);
        }
        if (action.type === BULK_FAILED) {
            return agentsUploadFailed(action);
        }
    }

    if (action.endpoint === 'users') {
        if (action.type === BULK_ADDED) {
            return usersUploadSuccess(action);
        }
        if (action.type === BULK_FAILED) {
            return usersUploadFailed(action);
        }
    }

    if (action.endpoint === 'companies') {
        if (action.type === BULK_ADDED) {
            return companiesUploadSuccess(action);
        }
        if (action.type === BULK_FAILED) {
            return companiesUploadFailed(action);
        }
    }

    if (action.endpoint === 'company_locations') {
        if (action.type === BULK_ADDED) {
            return locationsUploadSuccess(action);
        }
        if (action.type === BULK_FAILED) {
            return locationsUploadFailed(action);
        }
    }

    if (action.endpoint === 'rate_caps') {
        if (action.type === BULK_ADDED) {
            return rateCapsUploadSuccess(action);
        }
        if (action.type === BULK_FAILED) {
            return rateCapsUploadFailed(action);
        }
    }

    if (['agency:tmc_preferred:hotels', 'client_preferred:hotels'].includes(action.endpoint)) {
        if (action.type === BULK_ADDED) {
            return hotelsUploadSuccess(action);
        }
        if (action.type === BULK_FAILED) {
            return hotelsUploadFailed(action);
        }
    }

    if (action.endpoint === 'security_vetted_hotels') {
        if (action.type === BULK_ADDED) {
            return hotelsUploadSuccess(action);
        }
        if (action.type === BULK_FAILED) {
            return hotelsUploadFailed(action);
        }
    }

    return state;
}
