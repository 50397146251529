import 'airborne/cars/confirmation/types';

function initial() {
    return {uid: null, data: null, loading: false};
}

export default function bookingDetails(state, {type, uid, data}) {
    if (!state || type === 'CARS_TO_SEARCH_RESULT' || type === 'CARS_BOOKING_FAIL' || type === 'CARS_TO_CHECKOUT' || type === 'CARS_TO_DIRECT_SELL_CHECKOUT') {
        return initial();
    }
    if (type === 'CARS_BOOKING_UID') {
        return {...initial(), uid: uid || null};
    }
    if (type === 'CARS_BOOKING_DETAILS_LOADING') {
        return {...state, loading: true};
    }
    if (type === 'CARS_BOOKING_DETAILS_LOADED') {
        return {...state, loading: false, data};
    }
    if (type === 'CARS_BOOKING_DETAILS_FAIL') {
        return {...state, loading: false, error: data};
    }
    if (type === 'CARS_BOOKING_CANCEL_LOADING') {
        return {...state, loading: true};
    }
    if (type === 'CARS_BOOKING_CANCEL') {
        return {...state, loading: false};
    }
    if (type === 'CARS_BOOKING_CANCEL_FAIL') {
        return {...state, loading: false};
    }
    return state;
}
