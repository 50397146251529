import {loaded, loadAble} from 'airborne/search2/helpers/loadable';
import {combineReducers} from 'redux';
import omit from 'lodash/omit';
import get from 'lodash/get';
import set from 'lodash/set';
import without from 'lodash/without';
import isEmpty from 'lodash/isEmpty';
import {isEmptyDeep} from 'airborne/helpers/emptyDeep';
import {getLongDurationsByODIndex} from 'airborne/air/fare_search/helpers';
import 'airborne/air/fare_search/types';
import * as exchangeTypes from 'airborne/air/store/exchange/actionTypes';
import * as types from '../actionTypes';
import seatMap from './seatMap';
import fareFamilies from './fareFamilies';
import flightPrice from './flightPrice';

const filterActions = [
    types.AIR_SET_FILTERS,
    types.AIR_START_SEARCH,
    types.AIR_PIN_FLIGHT_NUMBERS,
    types.AIR_UNPIN_FLIGHT_NUMBERS,
];

const fareGroups = loadAble((state = {
    data: [],
    loading: false,
}, {type, data}) => {
    if (type === types.AIR_FARES_LOADED) {
        return loaded(state, data);
    }

    if (!state || type === types.AIR_START_SEARCH) {
        return {
            data: [],
            loading: false
        };
    }

    return state;
}, types.AIR_FARES_LOADING, types.AIR_FARES_FAIL);

const policyRules = ((state, {type, data}) => {
    if (!state) {
        return [];
    }

    if (type === types.AIR_SET_POLICY_RULES) {
        return data;
    }

    if (type === types.AIR_ADD_POLICY_RULES) {
        return [...state, ...data];
    }

    return state;
});

const currentPage = (state, {type, page}) => {
    if (!state || filterActions.includes(type)) {
        return 1;
    }

    if (type === types.AIR_SET_PAGE) {
        return page;
    }

    return state;
};

export const checkedFlightOptionKeysByGroup = (state = {}, {
    type,
    fareGroupKey, ODIndex, flightOptionKey, // AIR_FLIGHT_OPTION_CHECKED
}) => {
    if (type === types.AIR_FLIGHT_OPTION_CHECKED) {
        return {
            ...state,
            [fareGroupKey]: {
                ...state[fareGroupKey],
                [ODIndex]: flightOptionKey
            },
        };
    }

    return state;
};

function fareKeys(state, {type, fareGroupKey, flightOptionKeys, ticketIndex = 0}) {
    if (!state) {
        return {
            0: {
                fareGroupKey: null,
                flightOptionKeys: null,
            },
            1: {
                fareGroupKey: null,
                flightOptionKeys: null,
            }
        };
    }
    if (type === types.AIR_SET_FARE_KEYS) {
        return {
            ...state,
            [ticketIndex]: {
                fareGroupKey, flightOptionKeys
            }
        };
    }
    return state;
}

const checkoutStatus = loadAble(function (state, {type}) {
    if (!state) {
        return {
            loading: false,
        };
    }

    if ([types.AIR_TO_CHECKOUT, exchangeTypes.SET_CURRENT_STEP].includes(type)) {
        return {
            ...state,
            loading: false,
        };
    }

    return state;
}, types.AIR_CHECKOUT_LOADING, types.AIR_CHECKOUT_FAIL);


const filters = (state, {
    type,
    value,
    filterName,

    // For array filters to filter by value
    itemValue,

    // For Time filter, it needs options because of a lot of nested properties
    juncture,

    data,
    flightNumbers,
    ODIndex
}) => {
    if (!state || type === types.AIR_START_SEARCH) {
        return {};
    }
    if (type === types.AIR_SET_FILTERS) {
        return value;
    }
    if (type === types.AIR_RESET_CHECKBOX_FILTER_ITEM) {
        const target = get(state, filterName, []);
        const result = target.filter((val) => val !== itemValue);
        if (result.length) {
            return {
                ...state,
                [filterName]: result
            };
        }
        return omit(state, filterName);
    }
    if (type === types.AIR_RESET_SINGLE_FILTER) {
        return omit(state, filterName);
    }
    if (type === types.AIR_RESET_TIME_FILTER) {

        if (!ODIndex && typeof ODIndex !== 'number') {
            return omit(state, 'time');
        }

        let result = {...state.time};

        if (!juncture) {
            result = omit(result, ODIndex);
        }
        else {
            const part = result[ODIndex];
            set(result, [ODIndex], omit(part, juncture));
        }

        if (isEmptyDeep(result)) return omit(state, 'time');

        return {
            ...state,
            time: result
        };
    }

    if (type === types.AIR_FARES_LOADED && data) {
        const longDurationsByDest = getLongDurationsByODIndex(data);

        //each origin should has at least one flight option where durationMinutes less than longDurationsByDest
        const excludeLongFlightByDest = data.some(({originDestinations}) => {
            return originDestinations.every(({flightOptions}, index) => {
                return flightOptions.some(({durationMinutes}) => {
                    return durationMinutes < longDurationsByDest[index];
                });
            });
        });
        if (excludeLongFlightByDest) {
            return {excludeLongFlightByDest};
        }
    }

    if (type === types.AIR_PIN_FLIGHT_NUMBERS) {
        return {
            ...state,
            pinnedFlight: {
                ...state.pinnedFlight,
                [ODIndex]: [...(state.pinnedFlight?.[ODIndex] || []), ...flightNumbers],
            },
        };
    }

    if (type === types.AIR_UNPIN_FLIGHT_NUMBERS) {
        const {pinnedFlight, ...restFilters} = state;
        const restODs = omit(pinnedFlight, ODIndex);
        const restFNs = without(state.pinnedFlight[ODIndex], ...flightNumbers);

        if (restFNs.length) { // other FNs are still pinned in this OD
            return {
                ...state,
                pinnedFlight: {
                    ...pinnedFlight,
                    [ODIndex]: restFNs,
                },
            };
        }

        if (!isEmpty(restODs)) { // some FNs are pinned in other ODs
            return {...state, pinnedFlight: restODs};
        }

        // nothing is pinned
        return restFilters;

    }

    return state;
};

const filtersExlusions = (state, {type, fareGroupKey, ODIndex}) => {
    if (!state || filterActions.includes(type)) {
        return {};
    }

    if (type === types.AIR_SHOW_FILTERED_FLIGHT_OPTIONS) {
        return {
            ...state,
            [fareGroupKey]: ODIndex,
        };
    }

    return state;
};

const fareRules = loadAble((state, {type, fareGroupKey, data}) => {
    if (!state || type === types.AIR_START_SEARCH) {
        return {loading: false, data: {}};
    }

    if (type === types.AIR_FARE_RULES_LOADED) {
        return {
            ...state,
            data: {
                ...state.data,
                [fareGroupKey]: data,
            },
            loading: false,
        };
    }

    return state;
}, types.AIR_FARE_RULES_LOADING, types.AIR_FARE_RULES_FAIL);

const air = combineReducers({
    fareGroups,
    policyRules,
    flightPrice,
    currentPage,
    checkedFlightOptionKeysByGroup,
    fareKeys,
    checkoutStatus,
    filters,
    filtersExlusions,
    fareRules,
    seatMap,
    fareFamilies,
});

export default air;
