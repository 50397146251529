import gettext from 'airborne/gettext';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';

const MIN_CHOICE = 0.1;
const MIN_CITY_CHOICE = 5;
const CHOICES = [0.1, 0.5, 1, 2, 5, 10, 15, 20, 25, 30, 50];
const CAR_CITY_CHOICES = [5, 10, 15, 20, 25, 30, 50];
const CITY_CHOICES = [0, ...CAR_CITY_CHOICES];
const CITY_TYPE = 'c';
const AIRPORT_TYPE = 'a';

function isHotel(destination) {
    return destination.value && destination.value.startsWith('Hotel:');
}

function isCity(destination) {
    return destination && destination.type === CITY_TYPE;
}

export function isAirport(destination) {
    return destination && destination.type === AIRPORT_TYPE;
}

function inUnits(distance, unit) {
    return unit === 'KM'
        ? gettext('{count} km',  {count: distance})
        : gettext('{count} miles',  {count: distance});
}

function addLabels(list, unit) {
    return list.map((distance)=> (
        distance
            ? [distance, inUnits(distance, unit)]
            : [distance, gettext('Only')]
    ));
}

export function getDistanceChoices(unit, destination, value) {
    if (!destination) return null;
    if (isHotel(destination)) return null;
    if (value && !CHOICES.includes(value)) return null;
    if (isCity(destination)) return addLabels(CITY_CHOICES, unit);

    return addLabels(CHOICES, unit);
}

export function getCarsDistanceChoices(unit, destination, gds) {
    if (!destination) return null;
    if (isAirport(destination) && gds !== 'sabre') return null;
    if (isCity(destination)) return addLabels(CAR_CITY_CHOICES, unit);

    return addLabels(CHOICES, unit);
}

export function getAllDistanceChoices(unit) {
    return addLabels(CHOICES, unit);
}

export function minDistance(destination) {
    return isCity(destination) ? MIN_CITY_CHOICE : MIN_CHOICE;
}

function module(number) {
    return (number < 0) ? -number : number;
}

export function fixDistance(destination, distance) {
    const choices = isCity(destination) ? CITY_CHOICES : CHOICES;
    if (destination && destination.type === 'l' || choices.includes(distance)) {
        return distance;
    }
    const [nearest] = sortBy(choices, (choice)=>
        module(choice - distance)
    );
    return Math.max(nearest, minDistance(destination));
}

export function format(distance, units, direction) {
    distance = round(distance, 1);
    units = units === 'KM' ? gettext('km') : gettext('mi');

    if (distance === 0) {
        return gettext('N/A');
    }

    return `${distance} ${units} ${direction}`;
}

export function destinationTooltip(destination, companyLocation) {
    if (companyLocation) {
        return gettext('Distance and direction from selected company location');
    }
    if (isCity(destination)) {
        return gettext('Distance and direction from the geographic center of the city');
    }
    else {
        return gettext('Distance and direction from specified location');
    }
}
