import React from 'react';
import find from 'lodash/find';
import {getSections as getCompanySections} from 'midoffice/companies/SettingsMenu';
import {getSections as getAgencySections} from 'midoffice/agencies/SettingsMenu';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';

import gettext from 'airborne/gettext';
import ActionableCounter from 'airborne/common/ActionableCounter';
import {hasAccess} from 'midoffice/helpers/permission';

const BOOKING_RECORDS_PRODUCTS = ['hotels', 'cars', 'air', 'rail', 'external'];

function wrapWithActionableCounter(item) {
    return (<ActionableCounter placement="right">{item}</ActionableCounter>);
}

export const getProductList = () => [
    {id: 'general', title: gettext('General'), singular: gettext('General')},
    {id: 'hotels', title: gettext('Hotels', {count: 2}), singular: gettext('Hotel'), wrapper: wrapWithActionableCounter},
    {id: 'cars', title: gettext('Cars', {count: 2}), singular: gettext('Car')},
    {id: 'air', title: gettext('Flights', {count: 2}), singular: gettext('Flight')},
    {id: 'rail', title: gettext('Rail'), singular: gettext('Rail')},
    {id: 'external', title: gettext('External Bookings', {count: 2}), singular: gettext('External Bookings', {count: 1})},
    {id: 'policy-groups', title: gettext('Overrides'), singular: gettext('Overrides')},
];

export function getTitle(id) {
    return find(getProductList(), {id}).title;
}

export function getProducts(treeName) {
    const sections = treeName === 'companies' ? getCompanySections() : getAgencySections();
    const ids = Object.keys(sections).filter(
        (id)=> sections[id].length
    );

    return getProductList().filter(({id})=> ids.includes(id));
}

export function getBookingRecordsProducts() {
    const canShowExternalTab = hasAccess('aft:check_external_booking:view');
    const railEnabled = getFeatureFlag(null, 'RAIL_ENABLED');
    return getProductList().filter(({id})=> {
        if (id === 'external' && !canShowExternalTab) return false;
        if (id === 'rail' && !railEnabled) return false;
        return BOOKING_RECORDS_PRODUCTS.includes(id);
    });
}

export function getDefaultProduct(treeName) {
    const [defaultProduct] = getProducts(treeName);
    return defaultProduct.id;
}
