import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lodash/noop';
import compact from 'lodash/compact';

import Glyphicons from 'midoffice/components/Glyphicons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import {injectField} from 'midoffice/newforms/decorators';


@injectField
export default class Checkbox extends React.Component {
    static fieldProps = {
        labelCol: 0
    };

    static propTypes = {
        name: PropTypes.string.isRequired,
        label: PropTypes.node.isRequired,
        helpText: PropTypes.string,
        dataId: PropTypes.string,
        helpTooltip: PropTypes.bool,
        isCompact: PropTypes.bool,

        className: PropTypes.string,
        labelClassName: PropTypes.string,
        value: PropTypes.any,
        disabled: PropTypes.bool,
        'data-testid': PropTypes.string,

        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func
    };

    static defaultProps = {
        className: '',
        value: null,
        disabled: false,
        onChange: noop
    };

    getClassName() {
        const {className, isCompact} = this.props;
        return classNames(...compact(['checkbox', 'checkbox-rev', className, isCompact && 'no-padding']));
    }

    getLabelClassName() {
        const {labelClassName, disabled} = this.props;
        return classNames(labelClassName, {'control--disabled': disabled});
    }

    handleChange = (event)=> {
        this.props.onChange(event.target.checked, this.props.name);
    };

    renderHelp(helpText) {
        let {helpTooltip} = this.props;

        if (helpTooltip) {
            return (<OverlayTrigger placement="top" overlay={<Tooltip id="help">{helpText}</Tooltip>}>
                <span className="inputlabel"><Glyphicons bsClass="glyphicon" glyph="info-sign" /></span>
            </OverlayTrigger>);
        }

        if (helpText) {
            return (<span className="muted"> {helpText}</span>);
        }

        return null;
    }

    render() {
        const className = this.getClassName();
        const labelClassName = this.getLabelClassName();
        const {disabled, label, helpText, value: checked, dataId, 'data-testid': testId, name} = this.props;

        return (<div className={className}>
            <label className={labelClassName}>
                <input
                    type="checkbox"
                    data-id={dataId}
                    key={`checkbox-${name}`}
                    name={name}
                    disabled={disabled}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    onChange={this.handleChange}
                    data-testid={testId}
                    checked={checked}/>
                <span className="checkbox__icon" />
                {label}&nbsp;{this.renderHelp(helpText)}
            </label>
        </div>);
    }
}
