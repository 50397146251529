import settings from 'airborne/settings';


export const TEST_LANGUAGE = 'dummy';
export const ALL_LANGUAGES = settings.LANGUAGES || [];
export const LANGUAGES = ALL_LANGUAGES.filter(([id])=> id !== TEST_LANGUAGE);

export function getDefaultLanguage(obj, key) {
    const EN_LANGUAGE = 'en';
    const defaultLanguage = obj[key] || settings.DEFAULT_LANGUAGE || EN_LANGUAGE;

    return defaultLanguage;
}

export function getProperLanguage({settings, editable, initial}, key) {
    if (!editable) {
        return getDefaultLanguage(settings, key);
    }
    return getDefaultLanguage(initial, key);
}
